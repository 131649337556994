import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Swal from 'sweetalert2'
import ModalMateriais from "../../pages/Landing/modal_materiais";
import withReactContent from "sweetalert2-react-content";


function NavBar({ main = true }) {
    const { token, user } = useAuth();
    const [toggleMenuState, setToggleMenuState] = useState(false);
    const MySwal = withReactContent(Swal);

    function openModalMaterial(){
        MySwal.fire('Material',
        <ModalMateriais />
        ,'success');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ 'background': '#010C28' }}>
            <Link className={"navbar-brand mx-auto"} to="/" >
                <h1 className="h1 mx-5" style={{ 'fontWeight': '500', 'color': '#FFFFFF' }}>Cloud Cube</h1>
            </Link>
            <button onClick={() => {
                // setToggleMenuState(!toggleMenuState);
            }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                <span className="navbar-toggler-icon" style={{ 'color': 'white' }}></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {
                    main &&
                    <ul className="navbar-nav ms-4 mx-lg-auto">
                        <li className="nav-item">
                            <a className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} href="/#comoFunciona">Como funciona</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} href="/#nossasCapacidades">Nossas capacidades</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} href="#" onClick={()=>openModalMaterial()}>Materiais</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to="">Sobre</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to="">Veja soluções para E-commerce</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link mx-2" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to="/contato">Fale conosco</Link>
                        </li>
                    </ul>
                }

                {
                    !main && <ul className="navbar-nav ms-4 mx-lg-auto"></ul>
                }

                <ul className="navbar-nav me-5 ms-4 ms-lg-0">
                    <li className="nav-item me-2 my-2">
                        <Link className="btn btn-primary" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to="/calculate">Obter cotação agora</Link>
                    </li>
                    {user
                        ? <>
                            <li className="nav-item my-2">
                                <Link className="nav-link" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to={"/user/edit"}>
                                    <i className="fa-solid fa-circle-user fa-2xl" style={{ 'color': 'white' }} />
                                </Link>
                            </li>
                            <li className="nav-item my-2">
                                <Link className="nav-link" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to={"/carrinho/"}>
                                    <i className="fa-solid fa-cart-shopping fa-2xl" style={{ 'color': 'white' }} />
                                </Link>
                            </li>
                        </> : <li className="nav-item my-2">
                            <Link className="btn btn-primary" style={{ 'fontWeight': '600', 'color': '#FFFFFF' }} to="/signin">Signin</Link>
                        </li>}
                </ul>
            </div>

        </nav>
    )
}

export default NavBar

