import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import api from "../services/api";

export function ProtectedRoute({ children }) {
    const { token, user, signOut } = useAuth();
    const [stauts, setStatus] = useState('pending');
    if (!user) {
        return <Navigate to="/signin" />;
    }

    api.get('/user/me').then(response => {
        if (response.data.current_user) {
            setStatus('success')
        } else {
            setStatus('fail')
        }
    }).catch(error => {
        setStatus('fail')
    });

    if (stauts == 'success') {
        return children;
    }
    if (stauts == 'fail') {
        signOut();
        return <Navigate to="/signin" />;
    }
    return <></>
};