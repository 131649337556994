import React from 'react';

import {
  SelectBlock,
  Label,
  SelectItem,
} from './styles'

import RSelect from 'react-select'
import { Restaurant } from '@material-ui/icons';

function Select({ label, name, options, touched, defaultOption = 'Select an option', ...rest }) {
  // let defaultNoOp = {label:defaultOption, value:''};
  // if(!options.find((e)=>e.value=='')){
  //   options.push(defaultNoOp);
  // }else{
  //   defaultNoOp.label = options.find((e)=>e.value=='').label;
  // }
  return (
    <>
      <SelectBlock>
        {
          label && 
        <Label htmlFor={name}><b>
          {label}
          </b>
          </Label>
        }
        <RSelect id={name} {...rest}
        name={name}
        value={options.find((e)=>e.value == rest.value)}
        onChange={(e)=>{
          if(rest.onChange){
            rest.onChange({
              target:{
                value: e.value,
                name: name
              }
            })
          }
        }}
        isSearchable={true} options={options} 
        className='react-select-container'
        classNamePrefix="react-select"
        style={rest.style}>
        </RSelect>
      </SelectBlock>
    </>
  )
}

export default Select;