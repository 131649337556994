import React, { useCallback, useEffect, useRef, useState } from "react";
import { Collapse, Row, Col } from 'react-bootstrap';
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import api from "../../services/api";
import { useDropzone } from 'react-dropzone'
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { initMercadoPago } from '@mercadopago/sdk-react'
import { Payment } from '@mercadopago/sdk-react';
import {formatCentsToReais} from "../../utils/currencyUtils"


function Checkout() {
    const location = useLocation();
    const navigate = useNavigate()
    const { token, user } = useAuth();

    // const [order, setOrder] = useState();
    const subtotal = location.state.subtotal;
    const shipping = location.state.shipping;
    const discount = location.state.discount;    
    const total = location.state.total;

    useEffect(() => {
        console.log("Total: ", total);
        if (total === 0) {
            console.log("Total é 0. Redirecionando.")
            // Torna o pagamento desnecessário
            onSubmit({selectedPaymentMethod: "free", formData: {}})
            return;
        }

        initMercadoPago("APP_USR-a901e190-12e0-491c-bae6-f9bd1fccf91e", {locale: 'pt-BR',});
        //initMercadoPago("TEST-1459d1da-10e9-4639-8066-1ff62fb27df1", {locale: 'pt-BR',});
        return () => {
            window.paymentBrickController.unmount();
        };
    }, [])

    useEffect(() => {
        if (subtotal === undefined || shipping === undefined || discount === undefined || total === undefined) {
            console.log("Faltaram dados do carrinho. Redirecionando.")
            navigate('/carrinho');
        }
    }, [location])

    const initialization = {
        amount: total / 100,
        // preferenceId: "<PREFERENCE_ID>",
    };

    const customization = {
        paymentMethods: {
            // ticket: "all",
            // bankTransfer: "all",
            creditCard: "all",
            // debitCard: "all",
            // mercadoPago: "all",
        },
    };

    const onSubmit = async ({ selectedPaymentMethod, formData }) => {
        try {
            const response = await api.post("/carrinho/checkout", {
                cardData: formData,
            });
    
            console.log("Terminou pedido!");
            console.log(response.data);
            navigate(`/order/${response.data['order']['id']}`);
        } catch (error) {
            console.error(error);
        }
    };

    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };

    const onReady = async () => {
    /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
    */
    };


    return (
        <React.Fragment>
            <main className="container-fluid px-0">
                <NavBar />
                <Col className="mx-auto my-5 px-4" style={{ 'maxWidth': '800px' }}>
                    <div className="py-2 px-3" style={{ 'backgroundColor': '#FFFFFF', 'borderStyle': 'solid', 'borderWidth': '1px', 'borderColor': '#CDD5EF' }}>
                    <Row>
                        {/* TODO: Colocar resumo da compra aqui */}
                            <div className="p-2" style={{ 'backgroundColor': '#1A2754' }}>
                                <h5 className="m-0 ms-4" style={{ 'color': 'white' }}>
                                    Resumo de compra
                                </h5>
                            </div>
                            <div className="mb-4 px-3" style={{ 'maxHeight': '200px', 'overflow': 'auto' }}>
                                {/* <h5>Pedido #{order && order.id}</h5> */}
                                <p>Subtotal do produto: {formatCentsToReais(subtotal)}</p>
                                <p>Valor do frete: {formatCentsToReais(shipping)}</p>
                                {discount > 0 && <p>Valor do desconto: {formatCentsToReais(discount)}</p>}

                                <p className="fw-bold">Total: {formatCentsToReais(total)}</p>
                            </div>
                    </Row>
                    <Row>
                        <Payment
                            initialization={initialization}
                            customization={customization}
                            onSubmit={onSubmit}
                            onReady={onReady}
                            onError={onError}
                        />
                    </Row>
                    </div>
                </Col>
                <Footer />
            </main >
        </React.Fragment >
    )
}

export default Checkout
