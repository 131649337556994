import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 64px;
    width: 100vw;
`;

export const TopNav = styled.ul`
    display: flex;
    height: 64px;
    width: 100vw;
    top: 0px;
    position: fixed;
    background: #000;
    min-height: 64px;
    z-index: 9999;
    align-items: center;
    text-align: center;
`;

export const NavItem = styled.li`
    display: block;
    font-size: 1.5rem;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-right: auto;
    margin-left: auto
`;

export const ReturnButton = styled.button`
    background: url(./back-svgrepo-com.svg);
    border: none;
    height: 40px;
    width: 40px;
    filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(48deg) brightness(104%) contrast(104%);
`;

export const Footer = styled.footer`
    display: flex;
    height: 290px;
    width: 100vw;
    background: #000;
    margin-top: 80px;
    padding-top: 8px;
`;

export const Line = styled.hr`
    margin-top: 8px;
    margin-botton: 8px;
    height: 1px;
    width: 100vw;
    color: rgba(255, 255, 255, 0.62);
`

export const H1 = styled.h1`
    font-size: 3rem;
    font-family: "Lineto Circular", -apple-system, BlinkMacSystemFont;
    font-weight: 900;
    // text-align: center;
`