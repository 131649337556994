import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";

import Input from "../../components/Input";
import Select from "../../components/Select";
import { FieldLabel } from "../../components/RadioGroup/styles";
import { initialValues } from "./formikProps";

import { useAuth } from "../../hooks/auth";
import { Container, AnimationContainer, Button } from "./styles";
import api from "../../services/api";

import logoImg from "../../assets/logoMD.svg";
import { Label } from "../../components/Input/styles";
import Axios from "axios";
import * as Yup from 'yup';

const SignUp = () => {
  const MySwal = withReactContent(Swal);

  const { SignUp } = useAuth();
  const navigate = useNavigate();

  const ref = useRef();

  async function handleSubmit(values) {
    console.log(values)
    let path = (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/') + 'users';
    Axios.post(path, {
      user: {
        // name: values.name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation
      }
    }).then((e) => {
      MySwal.fire("Registro completo", "Faça login para entrar", 'success').then(() => {
        navigate('/')
      });
    }).catch((e) => {
      MySwal.fire("Invalid credentials", "", 'error');
    })
  }

  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Senha deve ter pelo menos 6 caracteres')
      .required('Senha é obrigatória'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
      .required('Confirmação de senha é obrigatória'),
  });

  return (
    <Container>
      <AnimationContainer>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="p-5">
          <img src={logoImg} alt="Cloud Cube" style={{ width: "100%" }} className="mb-5" />
        </div>
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          validateOnMount
          innerRef={ref}
          initialValues={{
            isSpecialist: false,
            country: ''
          }}
          validationSchema={SignupSchema}
        >
          {/* use renderProp to get the all the errrors */}
          {({ errors }) => {
            return (
              <Form>
                <Field
                  as={Input}
                  name="name"
                  type="text"
                  required
                  placeholder="Nome"
                />
                <Field
                  as={Input}
                  name="email"
                  placeholder="E-mail"
                  required
                />
                <Field
                  as={Input}
                  name="password"
                  type="password"
                  required
                  placeholder="Senha"
                />
                {/* render password error in the UI */}
                {!!errors.password && (
                  <p className="text-error">{errors.password}</p>
                )}
                <Field
                  as={Input}
                  name="password_confirmation"
                  type="password"
                  required
                  placeholder="Confirmação senha"
                />
                {/* render confirmPassword error in the UI */}
                {!!errors.confirmPassword && (
                  <p className="text-error">{errors.confirmPassword}</p>
                )}
                <Button type="submit">Registro</Button>
              </Form>
              );
            }}
        </Formik>
        <Link to="/signin">Voltar ao Login</Link>
      </AnimationContainer>
    </Container>
  );
};

export default SignUp;
