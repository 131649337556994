import React, { useEffect, useState } from "react"
import * as THREE from 'three';
import DxfParser from 'dxf-parser';
import { GCodeLoader } from 'three/examples/jsm/loaders/GCodeLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import api from "../../../services/api";
import { Link, useNavigate } from "react-router-dom";
import texture from '../../../assets/printTexture.png'

function Step4({ setOrder, orderInformation, setMaterials, materials, throwErrorMessage, user }) {
    const [signIn, setSignIn] = useState(false)
    const [loading, setLoading] = useState(true)
    const [paymentInfo, setPaymentInfo] = useState()
    const [updateView, setUpdateView] = useState(0)
    const [addrUser, setAddrUser] = useState({});
    const navigate = useNavigate()

    // const mp = new window.MercadoPago("APP_USR-cd8cbb55-c634-42c9-a1b4-25c6632165fc");

    useEffect(() => {
        localStorage.setItem('addrUser', JSON.stringify(addrUser));
    }, [addrUser]);

    // useEffect(() => {
    //     console.log("Setting up form ML,", user && !user.franquium_id && orderInformation?.metadata?.price);
    //     if (user && !user.franquium_id && orderInformation?.metadata?.price) {

    //         const cardForm = mp.cardForm({
    //             amount: (orderInformation.metadata.price.cents / 100.0).toFixed(2),
    //             iframe: true,
    //             form: {
    //                 id: "form-checkout",
    //                 cardNumber: {
    //                     id: "form-checkout__cardNumber",
    //                     placeholder: "Número do cartão",
    //                 },
    //                 expirationDate: {
    //                     id: "form-checkout__expirationDate",
    //                     placeholder: "MM/YY",
    //                 },
    //                 securityCode: {
    //                     id: "form-checkout__securityCode",
    //                     placeholder: "Código de segurança",
    //                 },
    //                 cardholderName: {
    //                     id: "form-checkout__cardholderName",
    //                     placeholder: "Titular do cartão",
    //                 },
    //                 issuer: {
    //                     id: "form-checkout__issuer",
    //                     placeholder: "Banco emissor",
    //                 },
    //                 installments: {
    //                     id: "form-checkout__installments",
    //                     placeholder: "Parcelas",
    //                 },
    //                 identificationType: {
    //                     id: "form-checkout__identificationType",
    //                     placeholder: "Tipo de documento",
    //                 },
    //                 identificationNumber: {
    //                     id: "form-checkout__identificationNumber",
    //                     placeholder: "Número do documento",
    //                 },
    //                 cardholderEmail: {
    //                     id: "form-checkout__cardholderEmail",
    //                     placeholder: "E-mail",
    //                 },
    //             },
    //             callbacks: {
    //                 onFormMounted: error => {
    //                     if (error) return console.warn("Form Mounted handling error: ", error);
    //                     console.log("Form mounted");
    //                 },
    //                 onSubmit: (event => {
    //                     event.preventDefault();
    //                     const {
    //                         paymentMethodId: payment_method_id,
    //                         issuerId: issuer_id,
    //                         cardholderEmail: email,
    //                         amount,
    //                         token,
    //                         installments,
    //                         identificationNumber,
    //                         identificationType,
    //                     } = cardForm.getCardFormData();

    //                     sendOrder({
    //                         token,
    //                         issuer_id,
    //                         payment_method_id,
    //                         transaction_amount: Number(amount),
    //                         installments: Number(installments),
    //                         description: "CloudCube",
    //                         payer: {
    //                             email,
    //                             identification: {
    //                                 type: identificationType,
    //                                 number: identificationNumber,
    //                             },
    //                         },
    //                     });

    //                     // api.post('/pay', ).then((e)=>{
    //                     //     console.log("Data");
    //                     //     console.log(e.data);
    //                     //   })
    //                 }),
    //                 onFetching: (resource) => {
    //                     console.log("Fetching resource: ", resource);

    //                     // Animate progress bar
    //                     // const progressBar = document.querySelector(".progress-bar");
    //                     // progressBar.removeAttribute("value");

    //                     return () => {
    //                         //   progressBar.setAttribute("value", "0");
    //                     };
    //                 }
    //             },
    //         });
    //     }
    // }, [user, orderInformation]);


    function reset() {
        document.getElementById('container').innerHTML = '<div id="model" style="width: 100%; height: 500px; margin:0 auto;"> </div>';
    }

    function STLViewer(contents, elementID, model = 'gcode', fileType = null) {
        var elem = document.getElementById(elementID);
        var camera = new THREE.PerspectiveCamera(70, elem.clientWidth / elem.clientHeight, 1, 1000);
        var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(elem.clientWidth, elem.clientHeight);
        elem.appendChild(renderer.domElement);
        window.addEventListener('resize', function () {
            renderer.setSize(elem.clientWidth, elem.clientHeight);
            camera.aspect = elem.clientWidth / elem.clientHeight;
            camera.updateProjectionMatrix();
        }, false);
        var controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = false;
        controls.rotateSpeed = 0.5;
        controls.dampingFactor = 0.1;
        controls.enableZoom = true;
        controls.autoRotate = true;
        controls.autoRotateSpeed = .75;
        var scene = new THREE.Scene();
        scene.add(new THREE.HemisphereLight(0xffffff, 1.5));
        function proccessGeometry(geometry, trim = true, acc = null) {
            if (trim) {
                geometry.remove(geometry.children[0]);
            }
            // geometry.position.set( , - 20, 100 );
            // geometry.sourceType = "stl";
            // geometry.sourceFile = "Sample";
            // (new STLLoader()).load(model, function (geometry) {
            // var material = new THREE.MeshPhongMaterial({ 
            //     color: 0xff5533, 
            //     specular: 100, 
            //     shininess: 100 });
            // var mesh = new THREE.Mesh(geometry, material);
            scene.add(geometry);
            // var middle = new THREE.Vector3();
            // geometry.geometry.computeBoundingBox();
            // geometry.geometry.boundingBox.getCenter(middle);
            // geometry.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-middle.x, -middle.y, -middle.z ) );
            var ANIMATION_ID = null;
            var animate = function () {
                ANIMATION_ID = requestAnimationFrame(animate);
                controls.update();
                renderer.render(scene, camera);
            };
            animate();
            renderer.domElement.addEventListener("click", function () {
                // cancelAnimationFrame(ANIMATION_ID);
                controls.autoRotate = false;
                controls.update();
            }, true);
            const pgeometry = new THREE.PlaneGeometry(20 * 10, 20 * 10);
            const pmaterial = new THREE.MeshBasicMaterial({ color: 0xb7b7b7, side: THREE.DoubleSide });
            const pplane = new THREE.Mesh(pgeometry, pmaterial);
            pplane.position.z = -0.8;
            pplane.rotation.x = Math.PI / 2;
            scene.add(pplane);
            camera.position.x = 2.4286100135266975;
            camera.position.y = 63.05795263786257;
            camera.position.z = -165.28723651095814;
            window.camera = camera;
            if (trim) {
                geometry.position.set(-100, 0.8, 100);
            }
            let measure = new THREE.Vector3();
            pplane.geometry.computeBoundingBox();
            pplane.geometry.boundingBox.getSize(measure);
            let mmAcc = acc;
            // orc2d(mmAcc);
        }
        if (model == 'gcode') {
            let path = (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/') + orderInformation.file.gcode.name
            let loader = new GCodeLoader();
            // loader.setRequestHeader({ Authorization: api.defaults.headers['Authorization'] });
            // loader.setWithCredentials(true);
            loader.load(path, proccessGeometry)
        } else if (model == "stl") {
            var geometry = new STLLoader().parse(contents);
            let size = new THREE.Vector3();
            geometry.computeBoundingBox();
            geometry.boundingBox.getSize(size);
            console.log('aaaaquia----------------------')
            console.log(contents)
            console.log(geometry)
            console.log(size)
            setOrder('details', {
                ...orderInformation.details,
                "width": size.x,
                "height": size.y,
                "length": size.z,
            })
            geometry.sourceType = "stl";
            geometry.sourceFile = "Sample";
            const tex = new THREE.TextureLoader(texture)
            var material = new THREE.MeshPhongMaterial({
                // map: tex,
                color: 0xff5533,
                specular: 100,
                shininess: 100
            });
            var mesh = new THREE.Mesh(geometry, material);
            mesh.rotation.x = (Math.PI / 2) * -1;
            mesh.position.y += 0.8;
            proccessGeometry(mesh, false);
        } else if (model == 'svg') {
            if (fileType == 'svg') {
                var data = new SVGLoader().parse(contents);
                // geometry.sourceType = "svg";
                // geometry.sourceFile = "Sample";
                // var material = new THREE.MeshPhongMaterial({ 
                //     color: 0xff5533, 
                //     specular: 100, 
                //     shininess: 100 });
                // var mesh = new THREE.Mesh(geometry, material);
                const paths = data.paths;
                const group = new THREE.Group();
                let acc = 0;
                for (let i = 0; i < paths.length; i++) {
                    const path = paths[i];
                    const material = new THREE.MeshBasicMaterial({
                        color: path.color,
                        side: THREE.DoubleSide,
                        depthWrite: false
                    });
                    const shapes = SVGLoader.createShapes(path);
                    for (let j = 0; j < shapes.length; j++) {
                        const shape = shapes[j];
                        const geometry = new THREE.ShapeGeometry(shape);
                        const mesh = new THREE.Mesh(geometry, material);
                        group.add(mesh);
                        let measure = new THREE.Vector3();
                        mesh.geometry.computeBoundingBox();
                        let box = mesh.geometry.boundingBox.getSize(measure);
                        acc += measure.x + measure.y;
                    }
                }
                group.rotation.x = (Math.PI / 2) * -1;
                group.position.y = 2;
                // group.position.z = 0;
                group.position.z = -0.8;
                group.rotation.x = Math.PI / 2;
                proccessGeometry(group, false, acc);
            } else if (fileType == "dwg") {
                // See index.js in the sample for more details
                var parser = new window.DxfParser();
                var dxf = parser.parseSync(contents);
                // cadCanvas = new ThreeDxf.Viewer(dxf, document.getElementById('model'), 400, 400);
                // const data = new DXFLoader().parse(contents);
                // const group = new THREE.Group();
                // if (data && data.entities) {
                //     data.entities.forEach(ent => group.add(ent))
                // }
                // group.rotation.x = (Math.PI / 2) * -1;
                // group.position.y += 0.8;
                // proccessGeometry(group, false);
            }
        }
        // document.addEventListener('mousemove', onDocumentMouseMove, false); 
        // });
    }

    function orc() {
        setLoading(true)
        let model = orderInformation.file.pointer;
        let inp = orderInformation.file[model];
        let material = orderInformation.material.id;
        let quantidade = orderInformation.details.quantity;
        let formData = new FormData();
        formData.append('fileName', orderInformation.file.name);
        formData.append('quantidade', quantidade);
        formData.append('material', material);
        formData.append('width', orderInformation.details.width)
        formData.append('height', orderInformation.details.height)
        formData.append('length', orderInformation.details.length)
        if (orderInformation?.metadata?.orcamento_id) {
            formData.append('orcamento_id', orderInformation.metadata.orcamento_id);
        } else {
            formData.append('file', inp);
        }
        api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        api.post('orcamento', formData).then(e => {
            setOrder('metadata', {
                'info': e.data.info,
                'price': e.data.price,
                'orcamento_id': e.data.orcamento_id,
            })
            setOrder('file', {
                ...orderInformation.file,
                'gcode': {
                    'name': e.data.gcode,
                }
            })
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
            console.error(e);
        });
    }

    function orc2d(acc) {
        console.log('teste orc 2d 1')
        setLoading(4)
        let formData = new FormData();
        formData.append('2dmm', acc);
        formData.append('quantidade', orderInformation.details.quantity);
        formData.append('material', orderInformation.material.id);
        // loading = true;
        api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        api.post('orcamento2d', formData).then(e => {
            setOrder('metadata', {
                'info': e.data.info,
                'price': e.data.price,
            })
            setOrder('file', {
                ...orderInformation.file,
                'gcode': {
                    'name': e.data.gcode,
                }
            })
            setLoading(false)
        });
    }

    function sendOrder(cardData = {}) {
        let addrUser = JSON.parse(localStorage.getItem('addrUser') || '{}');
        let formData = new FormData();
        formData.append('userId', user.id);
        formData.append('orcId', orderInformation.metadata.orcamento_id);
        formData.append('qtd', orderInformation.details?.quantity);
        formData.append('order[obs]', orderInformation.franqueado?.obs || '');
        // formData.append('order[status]', orderInformation.franqueado?.status || '' || '');
        formData.append('order[payment_type]', orderInformation.franqueado?.payment_type || '');
        formData.append('order[shipping]', orderInformation.franqueado?.shipping || '');
        formData.append('order[postal_code]', orderInformation.franqueado?.postal_code || addrUser.cep || '');
        formData.append('order[width]', orderInformation.franqueado?.width || '');
        formData.append('order[height]', orderInformation.franqueado?.height || '');
        formData.append('order[length]', orderInformation.franqueado?.length || '');
        formData.append('order[weight]', orderInformation.franqueado?.weight || '');
        formData.append('order[insurance_value]', orderInformation.franqueado?.insurance_value || '');
        formData.append('order[address]', addrUser.address || '');
        formData.append('order[number]', addrUser.number || '');
        formData.append('order[complemento]', addrUser.complemento || '');
        if (Object.values(cardData).length > 0) {
            formData.append('card', JSON.stringify(cardData));
        }


        // var object = {};
        // formData.forEach(function (value, key) {
        //     object[key] = value;
        // });

        api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        api.post('/orders.json', formData).then(e => {
            navigate('/carrinho')
        });
    }

    function update() {
        setUpdateView(prevState => {
            return prevState + 1
        })
    }

    function handleMaterialChange(type, event) {
        setOrder(type, {
            'category': event.target.name,
            'id': event.target.value,
        })
        update()
    }

    function handleFranqueadoChange(event) {
        setOrder('franqueado', {
            ...orderInformation.franqueado,
            [event.target.name]: event.target.value
        })
    }

    function handleDetailsChange(event) {
        update()
        setOrder('details', {
            ...orderInformation.details,
            [event.target.name]: event.target.value,
        })
    }

    useEffect(() => {
        reset()
        let error = throwErrorMessage(4)
        if (orderInformation.file && !error) {
            let model = orderInformation.file.pointer;
            let inp = orderInformation.file[model];
            let nameFile;
            let file = inp;
            nameFile = inp.name;
            var reader = new FileReader();
            // reader.addEventListener('load', function (event) {
            //     var contents = event.target.result;
            //     STLViewer(contents, "model", model, nameFile.split(".").pop());
            // }, false);
            // if (reader.readAsBinaryString !== undefined) {
            //     reader.readAsBinaryString(file);
            // } else {
            //     reader.readAsArrayBuffer(file);
            // }

            if (model == "stl" || model == "svg") {
                var reader = new FileReader();
                reader.addEventListener('load', function (event) {
                    var contents = event.target.result;
                    model == "stl"
                        ? STLViewer(contents, "model", model)
                        : STLViewer(contents, "model", model, nameFile.split(".").pop());
                }, false);
                if (reader.readAsBinaryString !== undefined) {
                    reader.readAsBinaryString(file);
                } else {
                    reader.readAsArrayBuffer(file);
                }
            } else {
                STLViewer(null, "model", model);
            }

        }
        if (user?.franquium_id && !error) {
            setOrder('franqueado', {})
            api.post('order').then(response => {
                setPaymentInfo(response.data)
            })
        }
    }, [updateView, orderInformation.file.pointer])

    useEffect(() => {
        console.log('teste orc 2d 2')
        console.log(orderInformation.details)
        switch (orderInformation.setor) {
            case '3d':
                if (orderInformation.details.width) {
                    orc()
                }
                break
            case '2d':
                console.log('teste orc 2d 3')
                orc2d(null)
                break
        }
    }, [orderInformation.details, orderInformation.material])

    return (
        <React.Fragment>
            <h4 className="">
                Finalizar
            </h4>
            <hr />
            <div className="col-sm-12 col-md-4" style={{ backgroundColor: 'rgb(33, 33, 33)', color: 'white', padding: '10px', borderRadius: '5px' }}>
                {orderInformation.file
                    && <React.Fragment>
                        <div v-if="nameFile">
                            Visualizando: <b>{orderInformation.file.name && ''}</b>
                            <div>
                                {loading
                                    ? <div v-if="loading">
                                        <b>Orçando seu modelo...</b>
                                    </div>
                                    : orderInformation?.metadata?.info && <div>
                                        <ul>
                                            {orderInformation.metadata.info.map((value, index) => {
                                                return (
                                                    <li key={index}>{value}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <h5 className="text-white">Material</h5>
                            {materials && Object.keys(materials).map((value, index) => {
                                let allowedCategories = []
                                if (orderInformation.setor == '2d') {
                                    allowedCategories = ['Laser']
                                }
                                if (orderInformation.setor == '3d') {
                                    allowedCategories = ['Impressora 3D']
                                }
                                if (allowedCategories.includes(value)) {
                                    return (
                                        <div className="col-12" key={index}>
                                            <div>
                                                <div className="p2">
                                                    <h6 className="text-white">{value}</h6>
                                                    <select required name={value} className="material_select form-control mat"
                                                        onChange={event => {
                                                            handleMaterialChange('material', event)
                                                        }}
                                                        value={orderInformation.material.id}>
                                                        <option value="null">Selecione o material</option>
                                                        {materials[value].map((value, index) => {
                                                            return (
                                                                <option value={value.id} name={value.name} key={index}>
                                                                    {value.name}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="row">
                            <h5 className="text-white">Quantidade
                            </h5>
                            <div className="col-12">
                                <div className="input-group">
                                    <input type="number" required="required" name="quantity" className="form-control"
                                        onChange={handleDetailsChange}
                                        value={orderInformation.details?.quantity} />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5" v-if="nameFile">
                            <div className="col ">
                                <h4 className="text-white">Envie seu pedido</h4>
                                {user
                                    ? <React.Fragment>
                                        {user.franquium_id
                                            ? <React.Fragment>
                                                <h4 style={{ color: 'white' }}>Olá franqueado, preencha as informações a seguir</h4>
                                                <div>
                                                    Observação
                                                    <br />
                                                    <textarea required name="obs" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.obs} />
                                                </div>
                                                <div className=" my-3">
                                                    <input type="checkbox" defaultValue="Confirmado" name="status"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.status} /> Confirmar venda ?
                                                </div>
                                                <div>
                                                    <label>
                                                        Forma de pagamento
                                                    </label>
                                                    <br />
                                                    <select required name="payment_type" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.payment_type}>
                                                        {/* &lt;% Order.payment_types.each do |t,i|  %&gt;
                                                        <option value="<%= t %>">&lt;%= t.humanize %&gt;</option>
                                                        &lt;% end %&gt; */}
                                                        {paymentInfo && Object.keys(paymentInfo.payment_types).map((value, index) => {
                                                            return (
                                                                <option value={value} key={index}>{value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>
                                                        Forma de envio
                                                    </label>
                                                    <br />
                                                    <select required name="shipping" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.shipping}>
                                                        {/* &lt;% Order.shippings.each do |t,i|  %&gt;
                                                        <option value="<%= t %>">&lt;%= t.humanize %&gt;</option>
                                                        &lt;% end %&gt; */}
                                                        {paymentInfo && Object.keys(paymentInfo.shippings).map((value, index) => {
                                                            return (
                                                                <option value={value} key={index}>{value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {/* <div>
                                                    <label>
                                                        CEP
                                                    </label>
                                                    <br />
                                                    <input type="text" name="postal_code" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.postal_code} />
                                                </div>
                                                <div>
                                                    <label>
                                                        Largura
                                                    </label>
                                                    <br />
                                                    <input type="text" name="width" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.width} />
                                                </div>
                                                <div>
                                                    <label>
                                                        Altura
                                                    </label>
                                                    <br />
                                                    <input type="text" name="height" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.height} />
                                                </div>
                                                <div>
                                                    <label>
                                                        Profundidade
                                                    </label>
                                                    <br />
                                                    <input type="text" name="length" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.length} />
                                                </div>
                                                <div>
                                                    <label>
                                                        Peso
                                                    </label>
                                                    <br />
                                                    <input type="text" name="weight" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.weight} />
                                                </div> */}
                                                <div>
                                                    <label>
                                                        Valor de garantia
                                                    </label>
                                                    <br />
                                                    <input type="text" name="insurance_value" className="form-control"
                                                        onChange={handleFranqueadoChange}
                                                        value={orderInformation.franqueado?.insurance_value} />
                                                </div>
                                                <button type="submit" className="btn btn-primary my-1" onClick={() => {
                                                    sendOrder();
                                                }}>
                                                    Adicionar ao carrinho
                                                </button>
                                            </React.Fragment>
                                            :
                                            <>
                                                {/* <input type='number' value={addrUser.cep || ''} onChange={(v) => {
                                                    let cep = v.target.value.replace('.', '').replace('-', '');
                                                    setAddrUser(addrUser => ({
                                                        ...addrUser,
                                                        cep: cep,
                                                    }))
                                                    if (cep.length == 8) {
                                                        Axios.get('https://viacep.com.br/ws/' + cep + '/json/').then((e) => {
                                                            let cinfo = e.data;
                                                            setAddrUser(addrUser => ({
                                                                ...addrUser,
                                                                address: cinfo.logradouro,
                                                                complemento: cinfo.complemento,
                                                            }))

                                                        })
                                                    }
                                                }} className='form-control m-1' placeholder="CEP" ></input>

                                                <input type='text' value={addrUser.address || ''} onChange={(v) => {
                                                    let addr = v.target.value;
                                                    if (v.target) {
                                                        setAddrUser(addrUser => ({
                                                            ...addrUser,
                                                            address: addr,
                                                        }))
                                                    }

                                                }} className='form-control m-1' placeholder="Endereço" ></input>

                                                <input type='number' value={addrUser.number || ''} onChange={(v) => {
                                                    let number = v.target.value;
                                                    if (v.target) {
                                                        setAddrUser(addrUser => ({
                                                            ...addrUser,
                                                            number: number,
                                                        }))
                                                    }

                                                }} className='form-control m-1' placeholder="Número" ></input>

                                                <input type='text' value={addrUser.complemento || ''} onChange={(v) => {
                                                    let comp = v.target.value;
                                                    if (v.target) {
                                                        setAddrUser(addrUser => ({
                                                            ...addrUser,
                                                            complemento: comp,
                                                        }))
                                                    }

                                                }} className='form-control m-1' placeholder="Complemento" ></input> */}

                                                {/* ML GERENCIED */}
                                                {/* <form id="form-checkout">
                                                    <div id="form-checkout__cardNumber" className="containermp"></div>
                                                    <div id="form-checkout__expirationDate" className="containermp"></div>
                                                    <div id="form-checkout__securityCode" className="containermp"></div>
                                                    <input type="text" id="form-checkout__cardholderName" />
                                                    <select id="form-checkout__issuer"></select>
                                                    <select id="form-checkout__installments"></select>
                                                    <select id="form-checkout__identificationType"></select>
                                                    <input type="text" id="form-checkout__identificationNumber" />
                                                    <input type="email" id="form-checkout__cardholderEmail" />

                                                    <button type='submit' id="form-checkout__submit" className="btn btn-primary my-1" >
                                                        Enviar pedido
                                                    </button>
                                                </form> */}
                                                <button type="submit" className="btn btn-primary my-1" onClick={() => {
                                                    sendOrder();
                                                }}>
                                                    Adicionar ao carrinho
                                                </button>
                                            </>
                                        }
                                    </React.Fragment>
                                    : <Link to='/signin?fromBudget=true' className="btn btn-primary my-1" onClick={() => {
                                        localStorage.setItem('@cloudcube:order', JSON.stringify(orderInformation));
                                        // navigate("/signin?fromBudget=true");
                                    }}>
                                        Faça login para enviar seu pedido
                                    </Link>}
                            </div>
                        </div>
                    </React.Fragment>}
            </div>
            <div className="col-sm-12 col-md-8">
                <div className="row" >
                    <div className="col">
                        <b>Visualizar</b>
                        <br />
                        <div className="btn-group">
                            {orderInformation.setor == '3d'
                                ? <React.Fragment>
                                    <button className={
                                        (orderInformation.file.pointer == 'gcode'
                                            ? "btn btn-primary"
                                            : "btn btn-secondary") + (orderInformation.file.gcode == null
                                                ? ' disabled'
                                                : '')
                                    }
                                        onClick={() => {
                                            setOrder('file', {
                                                ...orderInformation.file,
                                                'pointer': 'gcode'
                                            })
                                        }}>
                                        GCode
                                    </button>
                                    <button className={orderInformation.file.pointer == 'stl' ? "btn btn-primary" : "btn btn-secondary"}
                                        onClick={() => {
                                            setOrder('file', {
                                                ...orderInformation.file,
                                                'pointer': 'stl'
                                            })
                                        }}>
                                        STL
                                    </button>
                                </React.Fragment>
                                : <button className={orderInformation.file.pointer == 'svg' ? "btn btn-primary" : "btn btn-secondary"}
                                    onClick={() => {
                                        setOrder('file', {
                                            ...orderInformation.file,
                                            'pointer': 'svg'
                                        })
                                    }}>
                                    2D
                                </button>}
                        </div >
                    </div >
                    <div id="container">
                        <div id="model" style={{ width: '100%', height: '500px', margin: '0 auto' }}>
                        </div>
                    </div>
                </div >
            </div >
        </React.Fragment >
    )
}

export default Step4