import React from "react";
import { Routes, Route } from "react-router-dom";

// import Route from "./Route";

import Landing from "../pages/Landing";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import NoMatch from "../pages/NoMatch";
import Budget from "../pages/budget";
import Orcamento from "../pages/orcamento";
import Order from "../pages/Profile/Order.js";
import Orders from "../pages/Profile/Orders.js";
import Edit from "../pages/Profile/Edit";
import Franqueado from "../pages/franqueado";
import ConfirmationStep from "../pages/orcamento/confirmationStep";
import Carrinho from "../pages/carrinho";
import Checkout from "../pages/Checkout/index.js";
import Contact from "../pages/Contact/index.js";
import { ProtectedRoute } from "./ProtectedRoute";

const Paths = () => (
  <Routes>
    <Route exact path="/" element={<Landing />} />
    <Route exact path="/signin" element={<SignIn />} />
    <Route exact path="/signup" element={<SignUp />} />
    <Route exact path="/order/:id" element={<ProtectedRoute><Order /></ProtectedRoute>} />
    <Route exact path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
    <Route exact path="/user/edit" element={<ProtectedRoute><Edit /></ProtectedRoute>} />
    <Route exact path="/recoverPassword" element={<ForgotPassword />} />
    <Route exact path="/calculate" element={<Orcamento />} />
    <Route exact path="/calculate/confirmation" element={<ConfirmationStep />} />
    <Route exact path="/franqueado" element={<ProtectedRoute><Franqueado /></ProtectedRoute>} />
    <Route exact path="/carrinho" element={<ProtectedRoute><Carrinho /></ProtectedRoute>} />
    <Route exact path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
    <Route exact path="/contato" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
    <Route path="*" element={NoMatch} />
  </Routes>
);

export default Paths;
