import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../services/api";
import {formatCurrency} from "../../utils/currencyUtils"


function CouponField({ onChange }) {
  const [couponCode, setCouponCode] = useState('');
  const [couponValidity, setcouponValidity] = useState('empty');
  const [debouncedCoupon, setDebouncedCoupon] = useState('');
  const [feedback, setFeedback] = useState('');

  // Atualiza o cupom debounced após o usuário parar de digitar por 500ms
  // Assim, a API não vai ser chamada pra cada caractere digitado pelo usuário
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedCoupon(couponCode);
    }, 500);

    // Limpa o timer se o cupom mudar
    return () => {
      clearTimeout(timerId);
    };
  }, [couponCode]);

  useEffect(() => {
    // Usa o cupom debounced
    if (debouncedCoupon === ""){
        setcouponValidity('empty');
        onChange(null);
        return
    }

    api.get(`/coupons/code/${debouncedCoupon}`).then((e) => {
        onChange(e.data);
        setcouponValidity('valid');
        var feedbackText = e.data.is_percentage ? `Cupom de ${parseFloat(e.data.discount_amount)}%` : `Cupom de ${formatCurrency(e.data.discount_amount)}`
        setFeedback(feedbackText)
    }).catch(err => {
        onChange(null);
        setcouponValidity('invalid');
        setFeedback(err.response.data.error)
    });
  }, [debouncedCoupon])
  

  return (
    <>
      <div className="form-floating mb-3">
        <input type="text" id="couponField" placeholder="ABC123" 
            onChange={e => setCouponCode(e.target.value)} className={"form-control" + (couponValidity == "invalid" ? " is-invalid" : "") + (couponValidity == "valid" ? " is-valid" : "")}
        />
        <label htmlFor="couponField">Cupom</label>
        {couponValidity === "valid" &&
          <div className="valid-feedback" data-testid="valid-feedback">
            {feedback}
          </div>
        }
        {couponValidity === "invalid" &&
          <div className="invalid-feedback" data-testid="invalid-feedback">
            {feedback}
          </div>}
      </div>
    </>
  )
};

export default CouponField;