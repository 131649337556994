import React, { useState, useEffect } from "react";

export default function ModalMateriais(){
    return <>
    <div id="materiais" className="row justify-content-center my-5">
          <div className="col-12 col-md-10">
            <div className="row justify-content-center">
             
              
              <h3 className="col-12 mt-5 mb-3">
              Impressão 3D 
              </h3> 
              


<div style={{textAlign: "justify"}}>
<header style={{textAlign: "center", fontWeight: "bolder"}}>ABS</header>   

<p> Obtido a partir do petróleo, o ABS (Acrilonitrila Butadieno Estireno) se trata de um polímero bastante utilizado por conta de suas boas propriedades, como maior resistência mecânica e resistência à temperatura e maior tenacidade. Esse material possui boa resistência 
à abrasão, pode ser pós-processado com vapores de acetona para um acabamento brilhante, pode ser pós-processado com lixa e pintado com tinta acrílica, o que o torna uma grande opção.</p>

<p> Características</p>

<p>- Boa resistência; </p>
<p>- Boa resistência à temperatura;</p>
<p>- Mais suscetível a empenamento.</p>




<header style={{textAlign: "center", fontWeight: "bolder"}}>PETG</header>

<p> O PETG (Polietileno Tereftalato Glicol) é um copoliéster termoplástico adaptado do famoso plástico PET (Tereftalato de Polietileno) adicionado de Glicol. Um dos principais motivos no qual se utiliza PETG ao invés de PET na impressão 3D é o problema que o PET apresenta ao se aquecer. Submetido a um sobreaquecimento, o PET faz-se nebuloso e frágil, característica inviável para utilização em impressão 3D. Já adicionado Glicol, este problema não ocorre. O importante de entender é que o PETG em relação ao PET é mais translúcido e tem menor ponto de fusão. Isso o torna ideal para uso na impressão 3D, permitindo peças resistentes e com fácil extrusão.
</p>
<p> Esse material é muito versátil e possui características próprias. Pode ser aparafusado, estampado a quente, serrado, cortado, jateado, rebitado, termoformado, usinado, pintado, polido e até mesmo dobrado a frio. Tudo isso sem lascar, esbranquiçar, rachar ou deformar.
É um material resistente ao impacto, que possui custo menor em comparação ao acrílico e ao policarbonato. Atende a uma grande gama de especificações, do segmento industrial ao de comunicação visual.
</p>

<p> O filamento PETG reúne ótimas características para a impressão 3D. Entre outras coisas, ele apresenta:</p>

<p>- Alta resistência ao desgaste;</p>
<p>- Alta resistência a corrosão e agentes oxidantes;</p>
<p>- Alta firmeza anti impacto;</p>
<p>- Fácil de ser impresso;</p>
<p>- Baixa deformação durante a impressão;</p>
<p>- Boa resistência térmica.</p>


<p> Essas características fazem o material PETG ganhar bastante destaque no universo da impressão 3D.</p>


<p> O filamento PETG é mais indicado quando:</p>

<p>- Peças que precisam de certa flexibilidade;</p>
<p>- Alta resistência mecânica;</p>
<p>- Alta resistência química;</p>
<p>- Boa resistência térmica (até aproximadamente 85°C);</p>
<p>- Peças sujeitas a pressão;</p>
<p>- Peças que serão esterilizadas.</p>

<p> Seguindo essas características, alguns exemplos de peças que podem ser feitas com o filamento PETG são recipientes de alimentos, protótipos mecânicos, peças de alto desgaste, reservatório de combustível de pequenos veículos, entre outros.
</p>
<p> O uso mais famoso do filamento PETG foi nas máscaras de proteção contra o Covid-19:
</p>






<header style={{textAlign: "center", fontWeight: "bolder"}}>PLA</header>

<p> PLA (Ácido Polilático) é um polímero termoplástico derivado de fontes renováveis como o milho, a mandioca, a beterraba ou a cana-de-açúcar. Por ter origem em matéria orgânica, o PLA é biodegradável e, logo, compostável e reciclável. É um dos materiais mais utilizados no mercado de impressão 3D por ser mais fácil para se utilizar na impressão 3D, garantindo bons resultados para as peças fabricadas.

<p> O filamento PLA que utilizamos foi desenvolvido para criação de protótipos funcionais e peças que necessitem de alta resistência e rigidez. São diversos os usos que podem ser possíveis para o filamento PLA. Por possuir uma baixa contração (warp), é comum que esse material seja indicado para imprimir peças grandes e técnicas, uma vez que é possível ter um controle maior das dimensões finais do objeto. Também pode ser útil em peças que vão sofrer atrito em seu uso. Embora não seja o material com maior resistência à abrasão, é suficiente para determinadas aplicações.
</p>
<p> Vantagens e desvantagens</p>

<p>- Material de fácil impressão</p>
<p>- Material de baixo custo que oferece risco pequeno à saúde</p>
<p>- Quanto às propriedades físicas do material, o PLA confere dureza superficial para algumas aplicações</p>
<p>- Seu detalhamento é geralmente muito elegante, não sendo propenso ao aparecimento de bolhas</p>
<p>- Proporciona também uma impressão com cores brilhantes, que resulta em um objeto vibrante depois de pronto, por ser um material de fácil pigmentação.</p>
<p>- Do ponto de vista ecológico, é biodegradável e sua composição utiliza matéria-prima renovável</p>

</p>

<header style={{textAlign: "center", fontWeight: "bolder"}}>TPU</header>

<p> O TPU (Poliuretano Termoplástico) é um elastômero termoplástico versátil que pode oferecer uma alta durabilidade e flexibilidade às peças impressas em 3D, além de manter as propriedades mecânicas e permitir a utilização em diversos setores industriais. Conhecido no mundo da impressão 3D como um filamento flexível que oferece um alto número de combinações com as propriedades físicas e químicas para aplicações nas áreas automotivas, de fios e cabos e até revestimentos esportivos. Com propriedades que variam entre as características do plástico e da borracha, o TPU promove diversos benefícios para a fabricação utilizando a manufatura aditiva que vão da sua resistência à tração, alto alongamento na ruptura e até a alta capacidade de carga.

<p> Este tipo de filamento possui três matérias primas básicas que são: o poliol, um extensor de corrente e um diisocianato. Pode ser considerado como um copolímero em bloco segmentado de forma linear e composto por alguns segmentos duros e macios. O poliéster é construído a partir de um poliol e um isocianato para fornecer a flexibilidade que é característica do filamento flexível TPU. Além disso, um segmento duro que é construído a partir de um extensor de cadeia, o que fornece ao TPU sua resistência e a sua propriedade de desempenho físico.
</p>
<p>Propriedades do TPU</p>
<p>A resistência à abrasão e aos riscos garantem uma durabilidade e valor estético aos produtos fabricados com este tipo de material. Estas propriedades fazem do TPU um ótimo material para aplicação crítica como em peças internas automotivas, aplicações esportivas e de lazer ou até peças técnicas, oferecendo excelentes resultados quando comparado a outros materiais. Sua resistência à abrasão do TPU também é maior quando comparado com o PVC ou as borrachas. Alguns tipos de filamento de TPU permitem ainda uma maior solidez de cor nas peças fabricadas, mostrando uma estabilidade superior à radiação ultravioleta mantendo as propriedades mecânicas do material. Também é o ideal para ser utilizado em aplicações eletrônicas pela sua versatilidade.
</p>
<p>Benefícios do TPU:</p>
<p>- Resistência a óleos, graxas e outros solventes;</p>
<p>- Boa flexibilidade em variadas temperaturas;</p>
<p>- Resistência a radiação de alta energia;</p>
<p>- Resistência ao impacto;</p>
<p>- Alta elasticidade em toda a sua faixa de dureza.</p>
<p>Os aditivos podem ajudar na estabilidade dimensional e na resistência ao calor, além de aumentar o retardo de chamas e até reduzir o atrito. Os TPUs aromáticos são uma variação que permite a resistência ao ataque de micróbios e suportam bem os produtos químicos, porém, como desvantagem é a sua descoloração quando em contato com a luz ultravioleta. Os TPUs alifáticos podem ser considerados leves e resistentes à descoloração através da exposição aos raios ultravioletas, também sendo opticamente transparentes. Isso os faz adequados para se trabalhar com o vidro e o envidraçamento de segurança.
</p>
<p>Esse conjunto de propriedades pode oferecer um desempenho interessante para diversos mercados:
</p>
<p>No setor automotivo, algumas peças internas como os botões de engrenagem e o painel de instrumento são um excelente exemplo da aplicabilidade do TPU;
</p>
<p>Na pecuária, a força e a durabilidade deste material fornecem etiquetas de identificação de animais de ótima qualidade, para uma ampla variedade de climas;
</p>
<p>No setor industrial, as vedações e as juntas de TPU estão atendendo à crescente demanda por materiais de alto desempenho, com baixa compressão e uma excelente resistência à abrasão e óleo.
</p>
<p>Ainda no setor industrial, os revestimento têxteis como as correias transportadoras, itens infláveis e até equipamentos militares são exemplos das ótimas propriedades mecânicas e resistência química;
</p>
<p>Na área de esporte o TPU consegue unir uma flexibilidade extrema e temperaturas baixas durante a utilização, além de resistência ao impacto e aos diversos ambientes;
</p>
<p>Outro exemplo do setor industrial é a área de tubos e mangueiras, principalmente quando se analisa aplicações altamente exigentes. Os tubos pneumáticos ou as mangueiras de união hidráulica se beneficiam da resistência do TPU e do seu comportamento.
</p>
<p>Segurança e benefícios ambientais</p>
<p>Este material é biodegradável e pode ser reciclado, o que por si só já pode ser considerado como um ponto a favor da sua utilização. Em comparação com o PVC, é resistente à abrasão e não racha ao longo do tempo. Em alguns dispositivos médicos, é aceito como uma alternativa ao PVC pois não é utilizado aceleradores de borracha e plastificantes que podem causar irritações na pele ou dermatites. Outra utilização importante é como isoladores de ambiente, melhorando efetivamente a eficiência energética dos edifícios, transportes e até de alguns equipamentos, reduzindo as emissões de carbono. Nos veículos aumentam a eficiência do combustível pois são mais leves que os metais e oferecem propriedades mecânicas similares ou até superiores.
</p>
</p>

<header style={{textAlign: "center", fontWeight: "bolder"}}>Resina</header>
<p> Essa é uma técnica mais característica que a impressão 3D “comum”. Dentre diversos métodos de impressão 3D disponíveis hoje em dia, um dos que vem chamando atenção das indústrias, empresas e profissionais da área é a com Resina 3D. Esse material está cada vez mais conhecido pela capacidade de se produzir protótipos e peças com alta precisão, qualidade e detalhes, tudo isso com um acabamento suave e visivelmente diferente dos demais tipos de impressão.

</p>
<p> Existem duas técnicas de utilizar impressão 3D com Resina: SLA (estereolitografia) e DLP (processamento digital de luz). Em ambas as técnicas, a Resina (líquido) é solidificada quando a luz UV entra em contato, isto é, as camadas são endurecidas até formar o modelo 3D final. É o contato da luz com a resina que traz a suavidade nos acabamentos e os detalhes característicos desse tipo de impressão.

</p>

<p> Devido ao seu alto nível de qualidade e, especialmente, maior resistência nos modelos impressos, a resina é muito utilizada por profissionais que desejam criar modelos e protótipos com o máximo possível de resolução e detalhes em suas peças como os médicos e dentistas, criando modelos realistas, únicos e extremamente resistentes para suas operações.

</p>

<p> Nós utilizamos a Resina 3D Cinza Opaca da 3D Fila por ser um material que apresenta alta velocidade de polimerização, que alcança grande velocidade de cura mantendo a qualidade final da impressão.

</p>






<h3 className="col-12 mt-5 mb-3">Usinagem CNC</h3>



<header style={{textAlign: "center", fontWeight: "bolder"}}>Chapa de alumínio</header>

<p> O alumínio é um metal relativamente fácil de usinar, o que o torna ótimo para usinagem CNC . Ele possui propriedades úteis para usinagem, como ser leve, maleável, tratável e boa durabilidade geral, podendo ser usado em uma ampla variedade de aplicações. As peças de alumínio usinadas em CNC começam como uma peça bruta de metal que é cortada pouco a pouco para criar uma forma ou objeto desejado. Este processo é feito com mais precisão com uma máquina CNC (Controle Numérico Computadorizado) que pode produzir quase qualquer objeto tridimensional projetado via software CAD (Desenho Assistido por Computador).</p>



<p>Vantagem</p>


<p>- As peças de alumínio são ótimas para aplicações que exigem alta resistência como à corrosão, tolerância ao calor, baixo peso, condutividade elétrica e versatilidade geral; </p>


<p>- Alta usinabilidade e ductilidade, boa relação resistência/peso;</p>


<p>- As chapas de alumínio têm alta condutividade térmica e elétrica, baixa densidade e resistência natural à corrosão.</p>



<p>Desvantagem</p>


<p>- Maiores restrições de geometria do que a impressão 3D</p>




<p>Trabalhamos com quase todos os tipos de fresadoras CNC, e somos capazes de usinar desde peças de trabalho simples, até geometrias complexas e orgânicas.</p>

<p>Características</p>


<p>Espessura limite da parede			     100 mm</p>


<p>Tamanho máximo da peça		    60 x 60 x 10 cm</p>




<header style={{textAlign: "center", fontWeight: "bolder"}}>UHMW</header>

<p>As chapas UHMW (Ultra-high Molecular Weight) são compostas por polietileno de ultra alto peso molecular, atóxico e antiaderente. Possuem elevada resistência ao impacto, bem como baixo peso específico e alta resistência à fadiga. Por isso, são muito utilizadas nas indústrias de papel e celulose, mineração, alimentícia e de bebidas.
</p>
<p>Principais características</p>


<p>O Polietileno de Ultra Alto Peso Molecular (UHMW/UHMWPE) apresenta características próprias, que o fazem superior a outros termoplásticos. Isso se dá, principalmente, por conta da resistência à abrasão (em algumas formas 15 vezes mais resistente à abrasão que o aço carbono), à fratura por impacto e ao tensofissuramento. Também apresenta as seguintes especificações:</p>

<ul>
<li> Baixo coeficiente de atrito;</li>
<li>Excelente resistência química; </li>
<li>Atóxico;</li>
<li>Auto Lubrificação; </li>
<li>Absorção de ruídos;</li>
<li>Não absorção de água. </li>
</ul>


<p>Seu coeficiente de atrito é comparável ao do PTFE/Teflon (politetrafluoretileno), mas ainda possui melhor resistência à abrasão. Trata-se de um excelente material de rolamento com propriedades deslizantes excepcionais e permanece resistente mesmo em baixas temperaturas. Esse é o material mais indicado para peças localizadas em ambientes abrasivos. E ainda é facilmente usinado, cortado, furado, fresado e torneado.</p>
<p>Estrutura e propriedades do UHMW</p>

<p>O UHMW incorpora todas as características do polietileno de alta densidade (HDPE), com o adicional de resistência a ácidos e álcalis concentrados.
Grande parte dos polímeros se liga facilmente à água. No entanto, como as olefinas não possuem esses grupos, o UHMW não absorve a água facilmente, o que dificulta a ligação a outros polímeros. Da mesma forma, essa característica faz com que sua superfície se torne escorregadia. Isso também é explicado pelo fato do UHMW não conter grupos químicos como ésteres, amidas ou grupos hidroxílicos,. Assim, ele também se torna resistente à radiação UV, microorganismos e à maioria dos produtos químicos. </p>


<p>As chapas UHMW podem ser utilizadas para diversas aplicações, como por exemplo: </p>
<p>
<ul>
<li>Revestimento de silos;</li>
<li>Anéis de vedação;</li>
<li>Gaxetas;</li>
<li>Placas de desgaste;</li>
<li>Placas de deslizamento;</li>
<li>Revestimento de caçambas;</li>
<li>Revestimento de tubulação;</li>
<li>Estrelas;</li>
<li>Guias;</li>
<li>Tanques rotativos;</li>
<li>Roldanas;</li>
<li>Roletas e outras peças técnicas.</li>
</ul>
</p>




<header style={{textAlign: "center", fontWeight: "bolder"}}>Madeira</header>
<p>Usinagem madeira cnc</p>
<p>A madeira e seus derivados são amplamente usados para a criação de diversos produtos, como móveis e itens utilizados para a decoração de ambientes. Dentre os derivados da madeira, o de uso mais comum é do MDF, que proporciona a criação de painéis, objetos de decoração e mais uma gama de produtos. Também se aplica a diversos segmentos, tais como comunicação visual, fabricação digital, moldes vacuum forming, prototipagem rápida, arquitetura, cenografia, design de produto, design de interiores, marcenarias, estandes, instrumentos musicais, maquetes, troféus, brinquedos, brindes, medalhas, modelismo, artesanato, gravação em baixo relevo, dentre outros.</p>

<p>Nossa companhia possui capacidade de atender clientes de todo território nacional, pois utiliza recursos tecnológicos, máquinas e profissionais capacitados. Nossa prestação de serviços de usinagem de madeira está preparada para oferecer toda a nossa experiência para atender as necessidades de nossos clientes. Envie seu orçamento! </p> 

<p>Características</p>

<p>Espessura limite da parede			     100 mm</p>

<p>Tamanho máximo da peça		    60 x 60 x 10 cm</p>


<header style={{textAlign: "center", fontWeight: "bolder"}}>Acrílico </header>

<p>Trata-se de um material termoplástico (material plástico que pode ser moldado nas mais variadas formas ao aumento de temperatura) e sintético (não natural) muito utilizado pela indústria, comércio, arquitetura e artes. Tem as vantagens de peso leve, preço barato e fácil moldagem. É um plástico amplamente utilizado, possui excelentes propriedades, é termoformado e tem boa processabilidade. Este plástico é eficiente para a construção de telas, lentes, placas e até janelas de barcos e submarinos.</p>

<p>Características</p>
<ul>
<li>Sua resistência é 5 vezes maior que a do vidro, possui características mais poderosas à prova de choque e anti-queda, e sua densidade é apenas metade da densidade do vidro;</li>

<li>Possui excelente resistência às intempéries, alta dureza e brilho da superfície, bom desempenho em altas temperaturas. Por exemplo, o acrílico não altera suas propriedades quando exposto aos raios ultravioleta por muito tempo enquanto outros plásticos ficam muito frágeis e quebradiços;</li>

<li>Resistência ao desgaste, com boa estabilidade e resistência a diversos produtos químicos, tornando-o muito durável.</li>
</ul>

<p>Características</p>

<p>Espessura limite da parede			     100 mm</p>

<p>Tamanho máximo da peça		    60 x 60 x 10 cm</p>

<header style={{textAlign: "center", fontWeight: "bolder"}}>Aço </header>

<p>O aço é um material amplamente utilizado na indústria devido às suas características e qualidades técnicas. Trata-se de uma liga metálica composta principalmente por ferro e carbono, além de outros elementos em menor proporção, como manganês, silício e enxofre.
Suas principais características são a alta resistência mecânica, dureza, tenacidade e durabilidade, garantindo maior resistência à deformação, fraturas e desgaste. Além disso, é fácil de ser cortado, furado e fresado, permitindo a obtenção de acabamentos de alta qualidade, fundamental em peças que exigem alto grau de precisão e estética. Os exemplos de uso do aço são variados e podem ser encontrados em praticamente todos os setores industriais. Desde peças mecânicas para máquinas e equipamentos, até estruturas de construção civil e peças para a indústria automotiva e aeronáutica.</p>

<p>As vantagens do uso do aço em usinagem CNC incluem sua alta resistência mecânica e à aderência, a facilidade de usinagem e a disponibilidade de diferentes tipos de aço no mercado. Além do mais, o aço é um material relativamente barato em comparação a outros metais. No entanto, o uso do aço apresenta persistência, como sua baixa resistência à temperatura, o que pode limitar seu uso em aplicações de alta temperatura. Nesse caso, é necessário um bom controle de qualidade durante o processo de usinagem para garantir a qualidade final da peça.</p>

<p>Características</p>

<p>Espessura limite da parede			     100 mm</p>

<p>Tamanho máximo da peça		    60 x 60 x 10 cm</p>



              </div>
            </div>
          </div>
        </div>
    </>;
}