import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer"
import logoMD from "../../assets/logoMD.svg";
import { Link } from "react-router-dom";

function EmptyCart() {
    return (
    <div className="row my-5 py-5 justify-content-center">
        <div className="col-md-4 col-12 mt-4 mb-2 text-center text-md-end">
            <i class="fa-solid fa-cart-shopping fa-6x"></i>
        </div>
        <div className="col-md-6 col-12 text-center">
            <p className="fs-2">Seu carrinho está vazio</p>

            <div className="d-grid gap-2 col-6 mx-auto">
                <Link to="/calculate" className="btn btn-outline-primary w-100 p-3 my-2">
                    Faça um orçamento
                </Link>
            </div>
        </div>
        
    </div>
    );
}

export default EmptyCart;