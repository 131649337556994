import React, { useCallback, useEffect, useRef, useState } from "react";
import { Collapse, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';


function BudgetForm({ materialList, selectedCategory, preselectedMaterial, preselectedQuantity, onValueChange }) {
    const [selectedMaterial, setSelectedMaterial] = useState(preselectedMaterial)
    const [selectedQuantity, setSelectedQuantity] = useState(preselectedQuantity || 1)
    const [selectedSheetDimensions, setSelectedSheetDimensions] = useState()

    const [sheetDimensionsList, setSheetDimensionsList] = useState({})

    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(materialList !== undefined && selectedCategory !== undefined)

        if (materialList === undefined || selectedCategory === undefined) return
        
        if (preselectedMaterial == undefined) {
            setSelectedMaterial(materialList[selectedCategory][0].id);
        }
    }, [materialList, selectedCategory]);

    useEffect(() => {
        if (selectedMaterial === undefined || materialList === undefined || selectedCategory === undefined) return

        var dims = materialList[selectedCategory].find((material) => material.id == selectedMaterial).sheet_dimensions;
        setSheetDimensionsList(dims);
        setSelectedSheetDimensions(dims.length > 0 ? dims[0].id : undefined)
    }, [selectedMaterial]);

    useEffect(() => {
        onValueChange({
            "selectedMaterial": selectedMaterial,
            "selectedQuantity": selectedQuantity,
            "selectedSheetDimensions": selectedSheetDimensions
        });
    }, [selectedMaterial, selectedQuantity, selectedSheetDimensions]);

    return (
        <Form className="mt-4">
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="fw-bold">Material</Form.Label>
                <Form.Control as="select"
                            onChange={(event) => { setSelectedMaterial(parseInt(event.target.value)); }}
                            style={{'border': '1px solid #0d6efd'}}
                >
                    {materialList && selectedCategory && materialList[selectedCategory].map((material, index) => {
                        if (material.id === preselectedMaterial) {
                            return <option key={material.id} value={material.id} selected>{material.name}</option>
                        } else {
                            return <option key={material.id} value={material.id}>{material.name}</option>
                        }
                    })}
                </Form.Control>
            </Form.Group>
            
            { Array.isArray(sheetDimensionsList) && sheetDimensionsList.length > 0 &&
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="fw-bold mt-3">Dimensões</Form.Label>
                <Form.Control as="select"
                    style={{'border': '1px solid #0d6efd'}}
                    onChange={(event) => {
                        setSelectedSheetDimensions(parseInt(event.target.value))
                    }}
                >
                    {sheetDimensionsList && sheetDimensionsList.map((dimension, index) => {
                        return <option key={dimension.id} value={dimension.id}>{dimension.width} x {dimension.length} cm</option>
                    })}
                </Form.Control>
            </Form.Group>
            }

            <Form.Group controlId="formBasicPassword">
                <Form.Label className="fw-bold mt-3">Quantidade</Form.Label>
                <Form.Control
                    className="ps-3"
                    type="number"
                    min="1"
                    name="quantity"
                    value={selectedQuantity}
                    style={{'border': '1px solid #0d6efd'}}
                    onChange={(event) => {
                        setSelectedQuantity(parseInt(event.target.value))
                    }} 
                />
            </Form.Group>
        </Form>
    )
};

export default BudgetForm;