import React, { useEffect, useState } from "react"
import { GCodeLoader } from "three/examples/jsm/loaders/GCodeLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Rhino3dmLoader } from "three/examples/jsm/loaders/3DMLoader";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import printTexture from "../../assets/printTexture.png";
import api from "../../services/api";
import Axios from "axios";

function dynamicReader(contents, format) {
    let bufferGeometry;
    switch (format) {
        case 'gcode':
            new GCodeLoader().parse(contents).traverse(function (o) {
                bufferGeometry = o.geometry;
            })
            return bufferGeometry;
        case 'stl':
            return new STLLoader().parse(contents);
        case 'obj':
            new OBJLoader().parse(contents).traverse(function (o) {
                if (o.isMesh) bufferGeometry = o.geometry;
            })
            return bufferGeometry;
        case '3dm':
            // new Rhino3dmLoader().parse(
            //     new TextEncoder().encode(contents).buffer,
            //     function (o) {
            //         if (o.isMesh) bufferGeometry = o.geometry;
            //     },
            // )
            // return bufferGeometry;
            return null;
        case 'svg':
            const svgData = new SVGLoader().parse(contents);
            svgData.paths.forEach((path, i) => {
                const shapes = path.toShapes(true);
                shapes.forEach((shape, j) => {
                    bufferGeometry = new THREE.ExtrudeGeometry(shape, {
                        depth: 5,
                        bevelEnabled: false
                    });
                });
            });
            return bufferGeometry;
        default:
            return null;
            break;
    }
}

export default function RenderModel({ model, modelURL, setModel, GCode = null, indexKey = 0, rotate = true, height = 0 }) {
    const [domHeight, setDomHeight] = useState(height);
    const [file, setFile] = useState();
    const [format, setFormat] = useState();
    const scene = new THREE.Scene();
    const reader = new FileReader();
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });

    function getFile() {
        if (modelURL) {
            setFormat(modelURL.split('.').reverse()[0].toLowerCase())
            Axios({
                url: modelURL,
                method: 'GET',
                responseType: 'blob'
            }).then(response => {
                setFile(response.data)
            })
        } else if (model) {
            if (GCode) {
                setFormat('gcode')
            } else {
                setFormat(model?.name.split('.').reverse()[0].toLowerCase())
            }
            setFile(model)
        } else {
            console.log('erro')
        }
    }

    function handleFile() {
        const elem = document.getElementById(indexKey);
        const camera = new THREE.PerspectiveCamera(70, elem.clientWidth / elem.clientHeight, 1, 1000);
        const boundingBox = new THREE.Box3();
        const size = new THREE.Vector3();

        while (elem.firstChild) {
            elem.removeChild(elem.firstChild);
        }
        renderer.setSize(elem.clientWidth, elem.clientHeight);
        elem.appendChild(renderer.domElement);

        reader.addEventListener('load', function (event) {
            const contents = GCode ? GCode : event.target.result;
            const geometry = dynamicReader(contents, format);

            geometry.computeBoundingBox();
            window.addEventListener('resize', function () {
                renderer.setSize(elem.clientWidth, elem.clientHeight);
                camera.aspect = elem.clientWidth / elem.clientHeight;
                camera.updateProjectionMatrix();
            }, false);

            renderer.setSize(elem.clientWidth, elem.clientHeight);
            camera.aspect = elem.clientWidth / elem.clientHeight;
            camera.updateProjectionMatrix();

            var controls = new OrbitControls(camera, renderer.domElement);
            controls.enableDamping = false;
            controls.rotateSpeed = 0.5;
            controls.dampingFactor = 0.1;
            controls.enableZoom = true;
            controls.autoRotate = rotate;
            controls.autoRotateSpeed = .75;

            const material = new THREE.MeshPhongMaterial({
                color: 0xff5533,
                specular: 100,
                shininess: 100
            });
            const mesh = new THREE.Mesh(geometry, material);

            mesh.rotation.x = (Math.PI / 2) * -1;
            boundingBox.copy(mesh.geometry.boundingBox);
            mesh.updateMatrixWorld(true);
            boundingBox.applyMatrix4(mesh.matrixWorld);
            boundingBox.getSize(size)
            mesh.geometry.center();
            mesh.position.y += 0.1;
            scene.add(mesh);

            var ANIMATION_ID = null;
            var animate = function () {
                ANIMATION_ID = requestAnimationFrame(animate);
                controls.update();
                renderer.render(scene, camera);
            };
            animate();


            renderer.domElement.addEventListener("click", function () {
                // cancelAnimationFrame(ANIMATION_ID);
                controls.autoRotate = false;
                controls.update();
            }, true);

            const pgeometry = new THREE.PlaneGeometry(20 * 10, 20 * 10);
            const pmaterial = new THREE.MeshBasicMaterial({ color: 0xb7b7b7, side: THREE.DoubleSide });
            const pplane = new THREE.Mesh(pgeometry, pmaterial);
            pplane.rotation.x = Math.PI / 2;
            pplane.position.y = (size.y / 2) * -1;
            scene.add(pplane);

            camera.position.x = 2.4286100135266975;
            camera.position.y = 63.05795263786257;
            camera.position.z = -165.28723651095814;
            window.camera = camera;

            scene.add(new THREE.HemisphereLight(0xffffff, 1.5));

            // var strMime = "image/jpeg";
            // var imgData = renderer.domElement.toDataURL(strMime);
            // console.log(imgData)

            // setDomHeight(size.y * 2)
        });
        reader.readAsBinaryString(file);
    }

    useEffect(() => {
        if (domHeight > 200) {
            renderer.domElement.style.height = domHeight
        }
    }, [domHeight])

    useEffect(() => {
        if (file) {
            handleFile();
        } else {
            getFile();
        }
    }, [file, GCode])

    return (
        <div id={indexKey} className="m-3 w-100" accept=".stl" style={{ 'height': domHeight, 'minHeight': '80px' }} />
    )
}

export function GetModelDimensions(model, setModelSize) {
    const file = model;
    const format = model?.name.split('.').reverse()[0].toLowerCase()
    const size = new THREE.Vector3();
    const reader = new FileReader();
    reader.addEventListener('load', function (event) {
        console.log("Carregando modelo STL...")
        var contents = event.target.result;
        // console.log('aaaa', contents)
        const geometry = dynamicReader(contents, format);
        geometry.computeBoundingBox();
        geometry.boundingBox.getSize(size);
        setModelSize({
            "width": size.x,
            "height": size.y,
            "length": size.z,
        })
    }, false);
    reader.readAsBinaryString(file);
}