import React from 'react';
// import { FormattedMessage } from 'react-intl';

import {
  InputBlock,
  Label,
 } from './styles'

function Input({ label, name, ...rest }) {
  return (
    <>
      {
        rest.sideBySide && 
        <InputBlock>  
        <Label htmlFor={name}>
          {/* <FormattedMessage 
            id={label}
            defaultMessage="batata"
            description=""
          /> */}
          <b>
            {label}
            </b>
          <input
            type="text"
            id={name}
            {...rest}
            style={Object.assign({
              background: 'white',
            }, rest.style ?? {})}
          />
          {rest.suffixContent ?? ''}
        </Label>
      </InputBlock>
      }
      {
        !rest.sideBySide && 
        <InputBlock>  
        <Label htmlFor={name}>
          {/* <FormattedMessage 
            id={label}
            defaultMessage="batata"
            description=""
          /> */}
          <b>
            {label}
            </b>
        </Label>
        <input
          type="text"
          id={name}
          {...rest}
          style={Object.assign({
            background: 'white',
          }, rest.style ?? {})}
        />
        {rest.suffixContent ?? ''}
      </InputBlock>
      }
      
    </>
  )
};

export default Input;