
import React, { useEffect, useState } from "react";
import { Collapse } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import CartBox from "../../components/CartBox";
import EmptyCart from "../../components/EmptyCart";
import CouponField from "../../components/CouponField";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import ShippingMethodBlock from "../../components/ShippingMethodBlock";
import AddressForm from "../../components/addressForm";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

function Carrinho() {
    const location = useLocation();
    const navigate = useNavigate()
    const { token, user } = useAuth();
    const [error, setError] = useState();
    const [address, setAddress] = useState();
    const [renderAddressForm, setRenderAddressForm] = useState(false);
    const [addressFormFlag, setAddressFormFlag] = useState();
    const [frete, setFrete] = useState();
    const [selectedFrete, setSelectedFrete] = useState();
    const [pickupAddress, setPickupAddress] = useState("(carregando)");
    const [order, setOrder] = useState();
    const [orders, setOrders] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [canPurchase, setCanPurchase] = useState(false);
    const [coupon, setCoupon] = useState();
    const [discountValue, setDiscountValue] = useState();

    const [cartIsEmpty, setCartIsEmpty] = useState(false);


    function getAddress() {
        console.log('location', location.state)
        if (location.state?.address) {
            setAddress(location.state.address)
        } else {
            api.get('address/' + user.id).then(response => {
                setAddress(response.data.address)
            }).catch(err => {
                console.log(err)
                setError('Erro ao carregar endereço')
            })
        }
    }

    function deleteAddress() {
        if (address.id) {
            api.delete('address/' + address.id)
        }
        setAddress();
    }

    function getFrete() {
        api.post('/getFrete', {
            address: address.cep,
        }).then(response => {
            console.log("Frete calculado.")
            setFrete(response.data.filter(value => !value.error))

            var retirar = response.data.find(value => value.id === -1)
            setPickupAddress(retirar.localization)
        }).catch(e => {
            console.log(e)
            setError('Erro no Melhor Envio')
        })
    }

    function getOrder() {
        api.get('/carrinho').then((e) => {
            setOrders(e.data.items);
            setSubtotal(e.data.items.map((order) => order.product.qtd * order.product.price_cents).reduce((partialSum, a) => partialSum + a, 0) / 100.0);

            if (e.data.items.length == 0) {
                setCartIsEmpty(true);
            }
        }).catch(err => {
            setError('Erro ao buscar os produtos no carrinho')
        });
    }

    function removeFromCart(id) {
        api.delete(`/product/${id}/remove_from_cart`).then((e) => {
            getOrder();
        }).catch(err => {
            setError('Erro ao remover um produto no carrinho')
        });
    }

    function RenderError() {
        return <div data-testid="render-error" className="alert alert-danger" role="alert">
            <div className="col col-10 mx-auto">
                <h5 className="m-0">{error}</h5>
            </div>
        </div>
    }

    const handleCouponChange = (newCoupon) => {
        setCoupon(newCoupon);
    }

    const handlePurchaseClick = () => {
        setLoading(true);

        api.post('/carrinho/finalizeOrder', {
            carrier: selectedFrete ? parseInt(selectedFrete.id) : -1,
            postal_code: address.cep,
            name: address?.nome,
            surname: address?.sobrenome,
            address: address?.logradouro,
            city: address?.cidade,
            address_district: address?.bairro,
            number: address?.numero,
            complemento: address?.complemento,
            coupon: coupon?.code
        }).then((e) => {
            console.log("Frete selecionado: ", selectedFrete);
            console.log("Data: ");
            console.log(e.data);

            navigate('/checkout', { state: { subtotal: subtotal * 100, shipping: selectedFrete.price * 100, discount: discountValue, total: total * 100 } });
        });
    }

    useEffect(() => {
        if (address?.cep.replace(/\D/g, "").length == 8) {
            getFrete()
        }
    }, [address?.cep])

    useEffect(() => {
        getOrder()
        getAddress()
    }, [token])

    useEffect(() => {
        setCanPurchase((address && address.nome && address.telefone && address.cidade) && !loading && selectedFrete);
    }, [address, selectedFrete, loading])

    useEffect(() => {
        var current_subtotal = parseFloat(subtotal);
        var frete_price = parseFloat(selectedFrete?.price) || 0;
        var discount = 0
        
        if (coupon?.discount_amount){
            if (coupon?.is_percentage){
                discount = (coupon?.discount_amount / 100) * current_subtotal
            } else {
                discount = coupon?.discount_amount
            }
        }

        setDiscountValue(discount)
        var new_total = current_subtotal + frete_price - discount;
        setTotal(new_total > 0 ? new_total : 0);
    }, [user, subtotal, orders, selectedFrete, address, coupon]);

    return (
        <React.Fragment>
            {renderAddressForm && <AddressForm address={address} setAddress={setAddress} setRenderAddressForm={setRenderAddressForm} flag={addressFormFlag} />}
            <main className="container-fluid px-0">
                <NavBar />
                {error && <RenderError />}
                <div className="col mx-auto my-5 px-4" style={{ 'maxWidth': '1230px' }}>
                    { cartIsEmpty
                    ? 
                        <EmptyCart/>
                    :
                        <div className="row">
                            <div className="col-12 col-lg-3 mx-auto mb-4  rounded" style={{ 'minWidth': '400px' }}>
                                <div className="py-2 px-3" style={{ 'backgroundColor': '#FFFFFF', 'borderStyle': 'solid', 'borderWidth': '1px', 'borderColor': '#CDD5EF' }}>
                                    <div className="p-2" style={{ 'backgroundColor': '#1A2754' }}>
                                        <h5 className="m-0 ms-4" style={{ 'color': 'white' }}>
                                            Endereço de entrega
                                        </h5>
                                    </div>
                                    {address
                                        ? <div className="m-4" style={{ 'position': 'relative' }}>
                                            {address.id && <div style={{ 'position': 'absolute', 'right': '0px', 'top': '0px' }}>
                                                <span style={{ 'color': 'red' }}>Padrão</span>
                                            </div>}
                                            <table style={{ 'color': '#808080' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>Para: {address.nome ? address.nome : <span className="text-danger">Não definido</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Telefone: {address.telefone ? address.telefone : <span className="text-danger">Não definido</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CEP: {address.cep}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Endereço: {address.logradouro}, {address.numero}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{address.bairro} - {address.cidade}/{address.uf}</td>
                                                    </tr>
                                                    {address?.complemento &&
                                                    <tr>
                                                        <td>{address.complemento}</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : <div className="m-4">Adicione um endereço de entrega</div>}
                                    <div className="row m-2">
                                        <div className="col-auto">
                                            <button className="btn" style={{ 'backgroundColor': '#EEF2FC' }}
                                                onClick={() => {
                                                    setAddress()
                                                    setAddressFormFlag()
                                                    setRenderAddressForm(true)
                                                }}>
                                                <i className="fa-solid fa-plus fa-sm" style={{ 'color': ' #3162E8 ' }} />
                                            </button>
                                            <button data-testid="delete-address-button" className="btn mx-2" style={{ 'backgroundColor': '#F8E6E9' }}
                                                onClick={deleteAddress}>
                                                <i className="fa-solid fa-trash fa-sm" style={{ 'color': ' #C70039 ' }} />
                                            </button>
                                        </div>
                                        {address && <div className="col-auto ms-auto"
                                            onClick={() => {
                                                setAddressFormFlag('edit')
                                                setRenderAddressForm(true)
                                            }}>
                                            <p style={{ 'color': '#3162E8' }}>Alterar</p>
                                        </div>}
                                        {(!address || !address.nome || !address.telefone || !address.cidade) && 
                                        <small className="row m-2 mt-4 text-danger">
                                            campos obrigatórios faltando
                                        </small>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 mx-auto mb-4  rounded" style={{ 'minWidth': '400px' }}>
                                <div className="py-2 px-3" style={{ 'backgroundColor': '#FFFFFF', 'borderStyle': 'solid', 'borderWidth': '1px', 'borderColor': '#CDD5EF' }}>
                                    <div className="p-2" style={{ 'backgroundColor': '#1A2754' }}>
                                        <h5 className="m-0 ms-4" style={{ 'color': 'white' }}>
                                            Método de envio
                                        </h5>
                                    </div>
                                    <ShippingMethodBlock methodList={frete} selected={selectedFrete} onShippingMethodChange={setSelectedFrete} />
                                </div>

                            </div>
                            <div className="col-12 col-lg-3 mx-auto mb-4  rounded" style={{ 'minWidth': '400px' }}>
                                <div className="py-2 px-3" style={{ 'backgroundColor': '#FFFFFF', 'borderStyle': 'solid', 'borderWidth': '1px', 'borderColor': '#CDD5EF' }}>
                                    <div className="p-2" style={{ 'backgroundColor': '#1A2754' }}>
                                        <h5 className="m-0 ms-4" style={{ 'color': 'white' }}>
                                            Resumo do pedido
                                        </h5>
                                    </div>
                                    <CartBox orders={orders} onRemoveButtonPress={removeFromCart}/>
                                    <div>
                                        <hr />
                                        <div className="row" >
                                            <CouponField onChange={handleCouponChange}/>
                                        </div>
                                        <hr />
                                        <div className="row" >
                                            <div className="col col-auto text-secondary">
                                                Subtotal
                                            </div>
                                            <div className="col col-auto ms-auto fw-bold">
                                                {subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </div>
                                        </div>
                                        <div className="row" >
                                            <div className="col col-auto text-secondary">
                                                Frete
                                            </div>
                                            <div className="col col-auto ms-auto fw-bold" style={selectedFrete ? {} : { 'color': 'red' }}>
                                                {selectedFrete
                                                    ? parseFloat(selectedFrete?.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                    : "Selecione o frete"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse in={coupon}>
                                        <div>
                                            <hr />
                                            <div className="row">
                                                <div className="col col-auto text-secondary">
                                                    Cupom de desconto aplicado
                                                </div>
                                                <div className="col col-auto ms-auto fw-bold">
                                                    - {parseFloat(discountValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <Collapse in={selectedFrete?.delivery_time === 0}>
                                        <div>
                                            <hr />
                                            <div className="row">
                                                <div className="col col-auto text-secondary small">
                                                    O produto deverá ser retirado em:<br/>
                                                    {pickupAddress}
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <div className="mb-4">
                                        <hr />
                                        <div className="row" >
                                            <div className="col col-auto text-secondary">
                                                Total
                                            </div>
                                            <div className="col col-auto ms-auto fw-bold text-end h5">
                                            {coupon ? (
                                                <>
                                                    <s className="text-muted small">
                                                        {(parseFloat(subtotal) + parseFloat(selectedFrete?.price || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </s>
                                                    <br />
                                                </>
                                            ) : null}
                                                {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </div>
                                        </div>
                                    </div>
                                    <button 
                                        className="btn btn-primary w-100 p-3 my-2" 
                                        disabled={!canPurchase}
                                        onClick={handlePurchaseClick}
                                    >
                                        { loading
                                        ? 'Carregando...'
                                        : 'Finalizar pedido'}
                                    </button>
                                    <Link to="/calculate" className="btn btn-outline-primary w-100 p-3 my-2">
                                        Continuar comprando
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </main >
        </React.Fragment >
    )
}

export default Carrinho