import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './styles/global.scss';

import Paths from './routes';

import AppProvider from './hooks';
import Landing from './pages/Landing';


if (process.env.NODE_ENV == "beta" || process.env.REACT_APP_ENVIRONMENT == 'beta') {
  require('./styles/beta.scss');
}

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Paths />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;