import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../services/api";
import RenderModel, { GetModelDimensions } from "../../components/RenderModel";
import { Collapse, Row, Col } from 'react-bootstrap';


function CartBox({ orders, onRemoveButtonPress }) {
    useEffect(() => {
    }, []);  

    return (
        <>
        <div className="row px-3 mt-3">
            <div className="col col-auto" style={{ 'width': '120px' }}>
                <p className="m-0 fw-bold" style={{ 'fontSize': '10px' }}>
                    Peça
                </p>
            </div>
            <div className="col col-auto">
                <p className="m-0 fw-bold" style={{ 'fontSize': '10px' }}>
                    Especificações
                </p>
            </div>
            <div className="col">
                <p className="m-0 fw-bold" style={{ 'fontSize': '10px' }}>
                    Quantidade
                </p>
            </div>
            <div className="col">
                <p className="float-end m-0 fw-bold" style={{ 'fontSize': '10px' }}>
                    Preço
                </p>
            </div>
        </div>
        {orders.map((order, index) => {
            return <div key={order.product.id} data-testid="order-item" className="row py-2 px-0 m-1" style={{ 'border': '1px solid #3162E8', 'borderRadius': '5px', 'position': 'relative' }}>
                <div className="col col-auto" style={{ 'width': '120px', 'textOverflow': 'ellipsis', 'overflow': 'hidden' }}>
                    <RenderModel modelURL={order.orcamento_file_url} indexKey={order.product.id} rotate={false} />
                    <p style={{ 'fontSize': '10px' }}>{order.orcamento_file_url.split('/').reverse()[0]}</p>
                </div>
                <Col style={{ 'color': '#808080', 'fontSize': '10px' }}>
                    <Row>
                        <Col>{order.material.category}</Col>
                    </Row>
                    <Row>
                        <Col>{order.material.name}</Col>
                    </Row>
                    <Row>
                        <Col>Dimensões</Col>
                    </Row>
                    <Row>
                        <Col>{order.product.width.toFixed(2)} x {order.product.height.toFixed(2)} x {order.product.length.toFixed(2)} <span style={{ 'fontSize': '8px' }}>mm</span></Col>
                    </Row>
                    <Row>
                        <Col>{(order.product.width * order.product.height * order.product.length).toFixed(2)} <span style={{ 'fontSize': '8px' }}>mm3</span></Col>
                    </Row>
                </Col>
                <Col style={{ 'textAlign': 'center' }}>
                    <span>
                        {order.product.qtd}
                    </span>
                </Col>
                <Col>
                    <span className="float-end">
                        {(order.product.price_cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </span>
                    <span className="float-end" style={{ 'fontSize': '10px' }}>
                        Valor p/ unidade
                    </span>
                </Col>
                <div className="w-100" style={{ 'position': 'absolute', 'bottom': '0px' }}>
                    <button data-testid="remove-button" className="btn float-end"
                        onClick={() => onRemoveButtonPress(order.product.id)}>
                        <i className="fa-solid fa-circle-xmark" style={{ 'color': 'red' }}></i>
                    </button>
                </div>
            </div>
        })}
        </>
    )
};

export default CartBox;