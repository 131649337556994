import React, { useCallback, useEffect, useRef, useState } from "react";
import {formatCentsToReais} from "../../utils/currencyUtils"
import { Link } from "react-router-dom";
import { Collapse } from 'react-bootstrap';
import api from "../../services/api";


function PedidoFranqueado({ key, order, statusChangeCallback }) {
  const [numeroNF, setNumeroNF] = useState('');
  const [printURL, setPrintURL] = useState('');

  useEffect(() => {
    if (order.status == "Em transporte"){
      api.get('/franqueado/getOrderShippingLabel/' + order.id + '/').then(response => {
        if (response.data.success){
          setPrintURL(response.data.result.url);
        }
        else {
          console.error(response.data)
        }
      })
    }
  }, [order.status])
  
  function getBadgeClass() {
    if (order.status == "Produção concluída" || order.status == "Em transporte") {
      return "badge rounded-pill bg-success";
    } else {
      return "badge rounded-pill bg-secondary";
    }
  }

  return (
    <>
        <div className="card" key={key}>
            <div className="card-body" key={key}>
                <h4 className="card-title">Pedido #{order.id} <small className={getBadgeClass()}>{order.status}</small></h4>
                <p className="card-text">Preço total: {formatCentsToReais(order.total_price_cents)}</p>
                <p className="card-text">Tipo de pagamento: {order.payment_type ? order.payment_type : "N/A"}</p>
                <p className="card-text">Quantidade de produtos: {order.products.length} ({order.products.map(product => product.material.name).join(', ')})</p>
                
                <Collapse in={order.status == 'Em expedição'}>
                <div className="form-floating my-3">
                  <input className="form-control" id="nfInput" placeholder="NF123456" onChange={e => setNumeroNF(e.target.value)}/>
                  <label for="nfInput">Número da nota fiscal</label>
                </div>
                </Collapse>
                
                <Link className="card-link btn btn-outline-secondary btn-sm" to={"/order/" + order.id}>
                Abrir pedido
                </Link>

                <Collapse in={printURL != ''}>
                <a className="card-link btn btn-primary btn-sm" href={printURL} target="_blank">Ver etiqueta</a>
                </Collapse>

                {(order.status == 'Em produção')        && <button className='card-link btn btn-primary btn-sm' onClick={() => statusChangeCallback(order.id, 'Produção concluída')}>Concluir produção</button>}
                {(order.status == 'Produção concluída') && <button className='card-link btn btn-success btn-sm' onClick={() => statusChangeCallback(order.id, 'Em expedição')}>Enviar para expedição</button>}
                {(order.status == 'Em expedição')       && <button className='card-link btn btn-primary btn-sm' onClick={() => statusChangeCallback(order.id, 'Em transporte', numeroNF)}>Enviar para transporte</button>}
                {(order.status == 'Em transporte')      && <button className='card-link btn btn-success btn-sm' onClick={() => statusChangeCallback(order.id, 'Completo')}>Concluir pedido</button>}
            </div>
        </div>
    </>
  )
};

export default PedidoFranqueado;