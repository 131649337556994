import Axios from 'axios';
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@cloudcube:token');
    const user = localStorage.getItem('@cloudcube:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ cpf, password }) => {
    let path = (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/') + 'users/sign_in';
    const response = await Axios.post(path, {
      "user": {
        "email": cpf,
        "password": password
      }
    });
    const { user, token, profile } = { user: response.data.user, token: response.data.user.token, profile: null };
    user.profile = profile;
    localStorage.setItem('@cloudcube:token', token);
    localStorage.setItem('@cloudcube:user', JSON.stringify({ profile, ...user }));

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@cloudcube:token');
    localStorage.removeItem('@cloudcube:user');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, token: data.token, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };