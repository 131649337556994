
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import Footer from "../../components/Footer"
import { Container, H1, Line, NavItem, ReturnButton, TopNav } from "./styles";
import SideBar from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";
import RenderModel from "../../components/RenderModel";
import {formatCentsToReais} from "../../utils/currencyUtils"

function Order() {
    const { id } = useParams();
    const { token, user } = useAuth();
    const [order, setOrder] = useState();
    const [log, setLog] = useState([]);

    useEffect(() => {
        api.get(`/orders/${id}`).then(response => {
            console.log("Orders:::");
            console.log(response.data);
            setOrder(response.data);
        })

        api.get('/order/' + id + '/log').then(response => {
            console.log(response.data)
            setLog(response.data)
        })
    }, [])

    return (
        <Container>
            <TopNav>
                <ReturnButton onClick={() => {
                    window.location.href = '/';
                }} />
                <NavItem>
                    Perfil
                </NavItem>
            </TopNav>
            <container className="row justify-content-start" style={{ minHeight: '500px' }}>
                <div className="col-12 col-xl-3">
                    <SideBar user={user} />
                </div>
                <div className="col-10 col-xl-8">
                    {order &&
                        <div className='row mt-5'>
                            <div className='col-12'>
                                <h1>{'Pedido #' + order.id} <small className={"badge rounded-pill bg-secondary"}>{order.status}</small></h1>
                                <div>{order.created_at.split("T")[0].split('-').reverse().join('/')}</div>
                            </div>

                            <div className='col-12 mt-2'>
                                <h2>Detalhes</h2>

                                <div className="card" style={{width: "18rem;"}}>
                                    <div className="card-body">
                                        <h5 className="card-title">{order.user.email}</h5>
                                    </div>
                                </div>

                                <h3>Entrega</h3>
                                <div className="card" style={{width: "18rem;"}}>
                                    <div className="card-body">
                                        <h5 className="card-title">{order.name}</h5>
                                        <h6 className="card-subtitle mb-2 text-body-secondary">Frete: {(order.shipping_value_cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} por {order.shipping_name}</h6>
                                        <p className="card-text">{order.address}, {order.number}, {order.postal_code}</p>
                                        {order.coupon &&
                                            <p className="card-text">Cupom de desconto: {order.coupon.is_percentage ? (parseInt(order.coupon.discount_amount) + "%") : (parseFloat(order.coupon.discount_amount)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        }
                                        <p className="card-text">Valor total: {formatCentsToReais(order.total_price_cents)}</p>
                                    </div>
                                </div>


                                <h3>Produtos</h3>

                                {order.products && order.products.map( (product) =>
                                    <div className="card" style={{width: "18rem;"}}>
                                        <div className="card-body">
                                            <p className="card-subtitle mb-2 text-body-secondary"><span className="fw-bold">Material:</span> {product.material.name}</p>
                                            <p className="card-subtitle mb-2 text-body-secondary"><span className="fw-bold">Quantidade:</span> {product.qtd}</p>
                                            <p className="card-text">Dimensões: {product.length.toFixed(2)}mm x {product.width.toFixed(2)}mm x {product.height.toFixed(2)}mm</p>
                                            <RenderModel modelURL={product.file} height={200} width={200}/>
                                            <p className="card-title fs-4 text-end fw-bold">{(product.price_cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                            <p className="card-title fs-6 text-end">(por unidade)</p>
                                            <a href={product.file} className="btn btn-sm btn-outline-primary card-link">Baixar STL</a>
                                            <a href={product.gcode} className="btn btn-sm btn-outline-primary card-link">Baixar G-code</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}
                    {log &&
                        <div className='container my-3'>
                            <h5>Log de transação</h5>
                            {log.map((e, i) => (<div key={i} className='card'>
                                <div className='card-body'>
                                    <p>
                                        {
                                            e.pay_log.status == "approved" && "Pagamento confirmado"
                                        }
                                        {e.pay_log.message}
                                    </p>
                                    <small>
                                        {e.created_at}
                                    </small>
                                </div>
                            </div>))}
                        </div>}
                </div>
            </container>
            <Footer />
        </Container>
    )
}

export default Order