import Axios from "axios";
import React, { useEffect, useState } from "react"
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

export default function AddressForm({ address, setAddress, setRenderAddressForm, flag }) {
    const { token, user } = useAuth();
    const [error, setError] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [validateCEP, setValidateCEP] = useState(false);
    const [saveAddress, setSaveAddress] = useState(false);

    function handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        setAddress(prevState => {
            return { ...prevState, [name]: value }
        })

        if (name == 'cep') {
            var cleanedCEP = value.replace(/\D/g, ""); // Remove todos os caracteres que não são números
            if (cleanedCEP.length == 8) {
                Axios.get('https://viacep.com.br/ws/' + cleanedCEP + '/json/').then(response => {
                    if (!response.data.erro) {
                        setValidateCEP(true)
                    } else {
                        setValidateCEP(false)
                    }
                }).catch(err => {
                    setValidateCEP(false)
                    console.log(err)
                })
            } else {
                setValidateCEP(false)
            }
        }
    }

    function exit() {
        setRenderAddressForm(false)
    }

    function send() {
        if (checkNull()) {
            if (saveAddress) {
                let formData = new FormData();
                formData.append('nome', address.nome);
                formData.append('sobrenome', address.sobrenome);
                formData.append('email', address.email);
                formData.append('telefone', address.telefone);
                formData.append('logradouro', address.logradouro);
                formData.append('numero', address.numero);
                formData.append('cidade', address.cidade);
                formData.append('uf', address.uf);
                formData.append('bairro', address.bairro);
                formData.append('cep', address.cep);
                formData.append('referencia', address.referencia);
                formData.append('user_id', user.id);

                api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
                api.post('/address/save', formData).then(response => {
                    setAddress(response.data.address)
                    exit();
                }).catch(err => {
                    setErrorMessage('Erro ao salvar os dados.')
                });
            } else {
                exit();
            }
        }
    }

    function edit() {
        if (checkNull()) {
            if (address.id) {
                let formData = new FormData();
                formData.append('id', address.id);
                formData.append('nome', address.nome);
                formData.append('sobrenome', address.sobrenome);
                formData.append('email', address.email);
                formData.append('telefone', address.telefone);
                formData.append('logradouro', address.logradouro);
                formData.append('numero', address.numero);
                formData.append('cidade', address.cidade);
                formData.append('uf', address.uf);
                formData.append('bairro', address.bairro);
                formData.append('cep', address.cep);
                formData.append('referencia', address.referencia);
                formData.append('user_id', user.id);

                api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
                api.post('/address/edit', formData).then(response => {
                    exit();
                }).catch(err => {
                    setErrorMessage('Erro ao salvar os dados.')
                });

            } else {
                exit();
            }
        }
    }

    function checkNull() {
        let err = []
        if (!address?.cep || !validateCEP) {
            err.push('cep')
        }

        setError(err)
        if (err.length > 0) {
            setErrorMessage('Campos obrigatórios estão vazios ou incorretos.')
            return false
        }
        return true
    }

    function buildFormValidationClass(value) {
        if (value == 'cep') {
            return validateCEP
                ? ' is-valid'
                : error.includes(value)
                    ? ' is-invalid'
                    : ''
        }
        return error.includes(value) ? ' is-invalid' : ''
    }

    return (
        <div className="w-100 h-100" style={{ 'position': 'fixed', 'zIndex': '99', 'backgroundColor': 'black', 'backgroundColor': 'rgba(0, 0, 0, .25)' }}>
            <div className="p-5" style={{ 'backgroundColor': 'white', 'position': 'absolute', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }}>
                {errorMessage
                    && <div class="alert alert-danger" role="alert">
                        <div className="col col-10 mx-auto">
                            <h5 className="m-0">{errorMessage}</h5>
                        </div>
                    </div>}
                <div className="p-5" style={{ 'overflow': 'auto', 'maxHeight': '80vh', 'maxWidth': '80vw' }}>
                    <h4 className="fw-bold">Endereço de entrega</h4>
                    <div className="my-5">
                        <div className="my-3">
                            <label>Nome</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('nome')}
                                type="text"
                                name="nome"
                                value={address?.nome}
                                onChange={handleChange} />
                        </div>
                        <div className="my-3">
                            <label>Sobrenome</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('sobrenome')}
                                type="text"
                                name="sobrenome"
                                value={address?.sobrenome}
                                onChange={handleChange} />
                        </div>
                        <div className="my-3">
                            <label>E-mail</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('email')}
                                type="text"
                                name="email"
                                value={address?.email}
                                onChange={handleChange} />
                        </div>
                        <div className="my-3">
                            <label>Número de telefone</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('telefone')}
                                type="text"
                                name="telefone"
                                value={address?.telefone}
                                onChange={handleChange} />
                        </div>
                    </div>
                    <div className="my-5">
                        <div className="my-3">
                            <label>Logradouro</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('logradouro')}
                                type="text"
                                name="logradouro"
                                value={address?.logradouro}
                                onChange={handleChange} />
                        </div>
                        <div className="my-3">
                            <label>Número</label>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('numero')}
                                type="text"
                                name="numero"
                                value={address?.numero}
                                onChange={handleChange} />
                        </div>
                        <div className="row my-3">
                            <div className="col col-6">
                                <label>Cidade</label>
                                <input className={"form-control border border-primary" + buildFormValidationClass('cidade')}
                                    type='text'
                                    name="cidade"
                                    value={address?.cidade}
                                    onChange={handleChange} />
                            </div>
                            <div className="col col-6">
                                <label>UF</label>
                                <input className={"form-control border border-primary" + buildFormValidationClass('uf')}
                                    type='text'
                                    name="uf"
                                    value={address?.uf}
                                    onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col col-6">
                                <label>Bairro</label>
                                <input className={"form-control border border-primary" + buildFormValidationClass('bairro')}
                                    type='text'
                                    name="bairro"
                                    value={address?.bairro}
                                    onChange={handleChange} />
                            </div>
                            <div className="col col-6">
                                <label>CEP</label>
                                <input className={"form-control border border-primary" + buildFormValidationClass('cep')}
                                    type='text'
                                    name="cep"
                                    value={address?.cep}
                                    onChange={handleChange} />
                            </div>
                        </div>
                        <div className="my-3">
                            <label>Referência</label> <small class="text-muted">(opcional)</small>
                            <input className={"form-control form-control-lg border border-primary" + buildFormValidationClass('referencia')}
                                type="textarea"
                                name="referencia"
                                value={address?.referencia}
                                onChange={handleChange} />
                        </div>
                    </div>
                    {!(flag == 'edit') && user
                        ? <div className="my-3">
                            <input type="checkbox"
                                name="save"
                                onClick={() => setSaveAddress(!saveAddress)} /> Usar como endereço padrão
                        </div>
                        : null}
                    <div className="row">
                        <div className="col-auto ms-auto">
                            <button className="btn btn-secondary" onClick={exit}>Cancelar</button>
                        </div>
                        {flag == 'edit'
                            ? <div className="col-auto">
                                <button className={"btn btn-primary"} onClick={edit}>Editar</button>
                            </div>
                            : <div className="col-auto">
                                <button className={"btn btn-primary"} onClick={send}>Confirmar</button>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )

}

