
import { Feedback } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import { Container, Footer, H1, Line, NavItem, ReturnButton, TopNav } from "./styles";

function Budget() {
    const { token, user } = useAuth();
    const [step, setStep] = useState(1);
    const [orderInformation, setOrderInformation] = useState();
    const [materials, setMaterials] = useState()
    const [errorMessage, setErrorMessage] = useState();
    const [lockProgress, setLockProgress] = useState(1);

    function setOrder(index, value) {
        if (index == 'remove') {
            setOrderInformation(prevState => {
                delete prevState[value]
                return (prevState)
            })
        } else {
            setOrderInformation(prevState => {
                return (
                    { ...prevState, [index]: value }
                )
            })
        }
    }

    function getMaterial() {
        api.get('material').then(response => {
            setMaterials(response.data.materials)
        })
    }

    function NextStep() {
        console.log('clean')
        setErrorMessage()
        setStep(prevState => {
            console.log(prevState)
            return prevState < 4
                ? prevState + 1
                : prevState;
        })
    }

    function PreviousStep() {
        console.log('clean')
        setErrorMessage()
        setStep(prevState => {
            console.log(prevState)
            return prevState > 1
                ? prevState - 1
                : prevState;
        })
    }

    function StepSelector() {
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <li className={step == 1 ? "page-item disabled" : "page-item"} style={{ cursor: 'pointer' }}>
                        <a tabIndex={-1} className="page-link"
                            onClick={() => PreviousStep()}>
                            Página Anterior
                        </a>
                    </li>
                    {[1, 2, 3, 4].map(index => {
                        return (
                            <li key={index} className={(step == index ? 'page-item active' : (lockProgress >= index ? 'page-item' : 'page-item disabled'))} style={{ cursor: 'pointer' }}>
                                <a className="page-link"
                                    onClick={() => {
                                        setErrorMessage()
                                        setStep(index)
                                    }}>
                                    {index}
                                </a>
                            </li>
                        )
                    })}
                    <li className={lockProgress > step ? "page-item" : "page-item disabled"} style={{ cursor: 'pointer' }}>
                        <a className="page-link"
                            onClick={() => NextStep()}>
                            Próxima Página
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }

    function throwErrorMessage(step) {
        let message = [];
        if (!orderInformation.material.id && step >= 2) {
            message.push('Nenhum material foi selecionado.')
        }
        console.log('teste erro', orderInformation.details?.quantity < 0)
        if ((!orderInformation.details?.quantity) && step >= 3) {
            message.push('Não foi selecionada uma quantidade.')
        }
        if ((orderInformation.details?.category == 'Laser' && !orderInformation.details?.thickness) && step >= 3) {
            console.log(orderInformation.details)
            message.push('Não foi selecionada uma espessura.')
        }
        if ((orderInformation.details?.category == 'Laser' && !orderInformation.details?.type) && step >= 3) {
            message.push('Não foi selecionado um tipo.')
        }
        if ((orderInformation.details?.category == 'Laser' && !orderInformation.details?.technique) && step >= 3) {
            message.push('Não foi selecionada uma técnica.')
        }
        if (!orderInformation.file && step == 4) {
            message.push('Nenhum aquivo foi enviado.')
        }
        if (message.length > 0) {
            setErrorMessage(message)
        }
        return message.length > 0
    }

    function StepRender(option) {
        switch (option) {
            case 4:
                console.log('option 4', option)
                return <Step4 setOrder={setOrder} setLockProgress={setLockProgress} orderInformation={orderInformation} setMaterials={setMaterials} materials={materials} throwErrorMessage={throwErrorMessage} user={user} />
            case 3:
                console.log('option 3', option)
                return <Step3 setOrder={setOrder} setLockProgress={setLockProgress} orderInformation={orderInformation} throwErrorMessage={throwErrorMessage} />
            case 2:
                console.log('option 2', option)
                return <Step2 setOrder={setOrder} setLockProgress={setLockProgress} orderInformation={orderInformation} throwErrorMessage={throwErrorMessage} />
            case 1:
                console.log('option 1', option)
                return <Step1 setOrder={setOrder} setLockProgress={setLockProgress} orderInformation={orderInformation} setMaterials={setMaterials} materials={materials} throwErrorMessage={throwErrorMessage} />
        }
    }

    useEffect(() => {
        getMaterial()
        let storedOrder = localStorage.getItem('@cloudcube:order')
        localStorage.removeItem('@cloudcube:order')
        if (storedOrder) {
            setOrderInformation(JSON.parse(storedOrder))
            console.log('JSON.parse(storedOrder) ------------------------\n', JSON.parse(storedOrder))
            setStep(3)
        }
    }, [])

    return (
        <Container>
            <NavBar
                main={false}
            >
            </NavBar>
            <div className="row">
                <div className="col-sm-12 col-md-8 mx-auto">
                    <div className="mt-5">
                        <div>
                            <H1>
                                Faça seu orçamento online sem compromisso
                            </H1>
                            <div className="my-5">
                                <StepSelector />
                            </div>
                            {errorMessage && <div className="row">
                                <div className="col">
                                    <div className="alert alert-danger">
                                        <ul>
                                            {errorMessage.map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        {value}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="row my-3 d-flex">
                                {StepRender(step)}
                                <div className="col-12 mt-5 px-5">
                                    <div className="row justify-content-end">
                                        <button type="button" className={step > 1 ? "btn btn-secondary col-6 col-md-2" : "btn btn-secondary col-6 col-md-2 disabled"}
                                            onClick={() => PreviousStep()}>
                                            Anterior
                                        </button>
                                        <button type="button" className={lockProgress > step ? "btn btn-primary col-6 col-md-2" : "btn btn-primary col-6 col-md-2 disabled"}
                                            onClick={() => NextStep()}>
                                            Próximo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ 'height': '10vh', 'display': 'inline-block' }} ></div>
            <Footer className="row">
                <Line />
                <div className="row" style={{ 'display': 'flex', 'width': '1176px', 'margin': 'auto' }}>
                    <Line />
                    <h6>Copyright © 2021 Cloud Cube</h6>
                </div>
            </Footer>
        </Container>
    )
}

export default Budget