
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../services/api";
import Footer from "../../components/Footer"
import { Container, H1, Line, NavItem, ReturnButton, TopNav } from "./styles";
import SideBar from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";

function Orders() {
    const { token, user } = useAuth();
    const [orders, setOrders] = useState([])

    useEffect(() => {
        api.get(`/orders/user_orders`).then(response => {
            setOrders(response.data);
        })
    }, [])

    return (
        <Container>
            <TopNav>
                <ReturnButton onClick={() => {
                    window.location.href = '/';
                }} />
                <NavItem>
                    Perfil
                </NavItem>
            </TopNav>
            <container className="row justify-content-start" style={{ minHeight: '500px' }}>
                <div className="col-12 col-xl-3">
                    <SideBar user={user} />
                </div>
                <div className="col-10 col-xl-8 p-5">
                    <h1>Meus pedidos</h1>

                    <table class='table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Qtd</th>
                                <th>Data do pedido</th>
                                <th>Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {orders.map(order => <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{order.status}</td>{console.log(order.products)}
                                <td>{order.products.length} ({order.products.map(product => product.material.name).join(', ')})</td>
                                <td>{order.created_at.split("T")[0].split('-').reverse().join('/')}</td>
                                <td>
                                    <Link className="btn btn-secondary btn-sm" to={"/order/" + order.id}>
                                        Visualizar
                                    </Link>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </container>
            <Footer />
        </Container>
    )
}

export default Orders