import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {formatCurrency} from "../../utils/currencyUtils"

const ShippingMethodBlock = ({ methodList, selected, onShippingMethodChange }) => {
    const [selectedFrete, setSelectedFrete] = useState(selected);

    const handleShippingMethodChange = (value) => {
        setSelectedFrete(value);
        onShippingMethodChange(value);
    };

    useEffect(() => {
    }, []);  

    return (
        <div className="mb-4 px-3" style={{ maxHeight: '200px', overflow: 'auto' }}>
            {methodList?.map((value, key) => (
                <Button
                    key={key}
                    variant={selectedFrete?.id === value.id ? 'outline-primary' : 'outline-primary border-0'}
                    className="w-100 text-left my-1 py-3"
                    onClick={() => handleShippingMethodChange(value)}
                >
                    <Row>
                        <Col>
                            <span className="float-start">{value.name}</span>
                        </Col>
                        <Col xs={4}>
                            <span className="float-start">
                                {value.delivery_time === 0 ? '---' : `${value.delivery_time} dias úteis`}
                            </span>
                        </Col>
                        <Col xs={3}>
                            <span className="float-end text-dark">
                                {formatCurrency(value.price)}
                            </span>
                        </Col>
                    </Row>
                </Button>
            ))}
        </div>
    );
};

export default ShippingMethodBlock;
