import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer"
import logoMD from "../../assets/logoMD.svg";
import Image01 from "../../assets/logoLG.png";
import Image02 from "../../assets/02.png";
import Image03 from "../../assets/03.png";
import Image04 from "../../assets/04.png";
import imgPeca01 from "../../assets/et1.png";
import imgPeca02 from "../../assets/et2.png";
import imgPeca03 from "../../assets/et3.png";
import imgPeca04 from "../../assets/et4.png";
import imgPeca05 from "../../assets/et5.png";
import imgPeca06 from "../../assets/spe1.png";
import imgPeca07 from "../../assets/spe2.png";
import imgPeca08 from "../../assets/t1.png";
import imgPeca09 from "../../assets/t2.png";
import imgPeca10 from "../../assets/t3.png";
import imgPeca11 from "../../assets/rppr1.png";
import imgPeca12 from "../../assets/rppr2.png";
import imgPeca13 from "../../assets/rppr3.png";
import imgPeca14 from "../../assets/rppr4.png";
import imgPeca15 from "../../assets/acs1.png";
import imgPeca16 from "../../assets/acs2.png";
import usinagemImg from "../../assets/cnc_v3.png";
import ImpressaoImg from "../../assets/3d_printing_v2.png";
import metalImg from "../../assets/corte_laser_1.png";
import imgObject from "../../assets/imgObjeto.png";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import prototipagemIcon from '../../assets/prototipagemIcon.svg';
import orcamentoIcon from '../../assets/orcamentoIcon.svg';
import fastTruckIcon from '../../assets/fastTruckIcon.svg';
import revisaoIcon from '../../assets/revisaoIcon.svg';
import subirArquivoIcon from '../../assets/subirArquivoIcon.svg';
import carteiraIcon from '../../assets/carteiraIcon.svg';
import fabricacaoIcon from '../../assets/fabricacaoIcon.svg';
import boxIcon from '../../assets/boxIcon.svg';
import pranchetaIcon from '../../assets/pranchetaIcon.svg';
import bg1 from '../../assets/bg1.webp';
import bg2 from '../../assets/bg2.png';
import { Parallax, Background } from 'react-parallax';
import groupFluxoImg from '../../assets/group_fluxo.png';

function Landing() {
  const [asd, setAsd] = useState(0)

  return (
    <main className="container-fluid px-0">
      <NavBar />
      <div className="col">
        <div className="row" style={{ 'backgroundColor': '#101A35' }}>
          <div className="col-12 col-lg-6 p-5 d-flex justify-content-center">
            <img className=" m-5" src={Image01} />
          </div>
          <div className="col-12 col-lg-6 px-0" >
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-10 col-lg-8">
                <div className="my-auto">
                  <h1 className="my-4" style={{ 'color': '#FFFFFF', 'fontSize': 'calc(8px + 2vw)' }} >
                    Produza peças personalizadas online
                  </h1>
                  <p style={{ 'color': '#FFFFFF', 'fontSize': 'calc(10px + .5vw)', 'maxWidth': '500px' }}>
                    Fabricação  sob demanda e prototipagem rápida.
                    Obtenha seu orçamento em  segundos, peças
                    entregues em poucos dias.
                  </p>
                  <Link to={'/calculate'} className="btn btn-primary my-4 p-3" style={{ 'fontSize': 'calc(10px + .6vw)' }}>
                    Obter cotação agora
                  </Link>
                  <p>
                    <i class="fa-solid fa-lock" style={{ 'color': '#FFFFFF' }} />
                    &nbsp;
                    <span style={{ 'color': '#FFFFFF', 'fontSize': 'calc(10px + .3vw)' }}>
                      Todos os envios são seguros e confidenciais
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center my-5">
          <div className="col-10 col-sm-8">
            <div className="row justify-content-around">
              <div className="col-12 col-md-12 col-lg m-3" style={{ 'backgroundColor': '#EEF2FC', 'minHeight': '100px' }}>
                <div className="row align-items-center h-100 p-3">
                  <div className="col-3 p-2">
                    <img src={prototipagemIcon} className="w-100" />
                  </div>
                  <div className="col-9">
                    <h5>Prototipagem rápida</h5>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-12 col-lg m-3" style={{ 'backgroundColor': '#EEF2FC', 'minHeight': '100px' }}>
                <div className="row align-items-center h-100 p-3">
                  <div className="col-3 p-2">
                    <img src={orcamentoIcon} className="w-100" />
                  </div>
                  <div className="col-9">
                    <h5>Orçamento em segundos</h5>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-12 col-lg m-3" style={{ 'backgroundColor': '#EEF2FC', 'minHeight': '100px' }}>
                <div className="row align-items-center h-100 p-3">
                  <div className="col-3 p-2">
                    <img src={fastTruckIcon} className="w-100" />
                  </div>
                  <div className="col-9">
                    <h5>Entregamos em todo o Brasil</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="nossasCapacidades" className="row justify-content-center my-5">
          <div className="col-12 col-md-10">
            <div className="row justify-content-center">
              <div className="col-12 my-5">
                <h1 className="text-center font-weight-bold">
                  Nossas máquinas
                </h1>
              </div>
              <div className="col-10 col-md-4 col-xl-3 m-0 m-xl-2 my-4">
                <div className="card border-0 h-100 p-3" style={{ 'backgroundColor': '#E8EAEE', 'borderRadius': '20px' }}>
                  <div className="card-header border-0" style={{ 'backgroundColor': 'transparent' }}>
                    <div className="col-12 px-5">
                      <div style={{height:'250px', alignItems:'center'}} className='mx-auto d-flex mt-4'>
                        <img className="" src={usinagemImg} style={{ 'height': '159.69px', 'width': '100%', 'objectFit': 'contain' }} />
                      </div>
                    </div>
                    <h5 className="col-12 mt-5 mb-3 font-weight-bold">
                      Usinagem CNC
                    </h5>
                    <span className="col-12 mt-5 pt-5" style={{'color': '#898989' }}>
                      <ul style={{'font-weight': '500'}}>
                        <li>Fresadora (3-eixos)</li>  
                        <li>Pós-processamento</li>
                      </ul> 
                    </span>
                  </div>
                  <div className="card-body d-flex flex-column" >
                    <div className="align-bottom mt-auto" style={{'font-size':'85%', 'font-weight': '500'}}>
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Entenda nossas capacidades em usinagem CNC
                      </Link>
                      <br />
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Confira nossos serviços de usinagem CNC
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-4 col-xl-3 m-0 m-xl-2 my-4">
                <div className="card border-0 h-100 p-3" style={{ 'backgroundColor': '#E8EAEE', 'borderRadius': '20px' }}>
                  <div className="card-header border-0" style={{ 'backgroundColor': 'transparent' }}>
                    <div className="col-12 px-5">
                      <img className="mx-auto d-block mt-4" src={ImpressaoImg} style={{ 'height': '250px', 'width': '100%', 'objectFit': 'contain' }} />
                    </div>
                    <h5 className="col-12 mt-5 mb-3">
                      Impressão 3D
                    </h5>
                    <span className="col-12" style={{'color': '#898989' }}>
                      <ul style={{'font-weight': '500'}}>
                        <li>FDM</li>
                      </ul> 
                    </span>
                  </div>
                  <div className="card-body d-flex flex-column mt-auto" >
                    <div className="align-bottom mt-auto mt-auto" style={{'font-size':'85%', 'font-weight': '500'}}>
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Entenda nossas capacidades em impressão 3D
                      </Link>
                      <br />
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Confira nossos serviços de impressão 3D
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-4 col-xl-3 m-0 m-xl-2 my-4">
                <div className="card border-0 h-100 p-3" style={{ 'backgroundColor': '#E8EAEE', 'borderRadius': '20px' }}>
                  <div className="card-header border-0" style={{ 'backgroundColor': 'transparent' }}>
                    <div className="col-12 px-5">
                      <div style={{height:'250px', alignItems:'center'}} className='mx-auto d-flex mt-4'>
                        <img className="" src={metalImg} style={{ 'height': '205px', 'width': '100%', 'objectFit': 'contain' }} />
                      </div>
                    </div>
                    <h5 className="col-12 mt-5 mb-3">
                      Corte a laser
                    </h5>
                    <span className="col-12" style={{'color': '#898989' }}>
                      <ul style={{'font-weight': '500'}}>
                        <li>MDF</li>  
                        <li>Acrílico</li>
                        <li>Madeira</li>
                        <li>Couro</li>
                      </ul> 
                    </span>
                  </div>
                  <div className="card-body d-flex flex-column" >
                    <div className="align-bottom mt-auto" style={{'font-size':'85%', 'font-weight': '500'}}>
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Entenda nossas capacidades em corte a laser
                      </Link>
                      <br />
                      <Link className="col-12" style={{ 'textDecoration': 'none' }}>
                        Confira nossos serviços de corte a laser
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div id="comoFunciona" className="row justify-content-center mb-5" style={{ 'backgroundColor': '#EEF2FC' }}>
          <div className="col-11 col-xl-8">
            <div className="row justify-content-center">
              <div className="col-12 my-5">
                <h1 className="text-center">
                  Como Cloud Cube funciona
                </h1>
              </div>
              <Carousel showThumbs={false}>
                <div>
                  <img src={Image02} />
                </div>
                <div>
                  <img src={Image03} />
                </div>
                <div>
                  <img src={Image04} />
                </div>
              </Carousel>
              <div className="my-5">
                <div className="row">
                  <div className="col-4 px-3">
                    <hr style={{ 'color': '#0A3DC2', 'height': '2px', 'opacity': '1' }} />
                  </div>
                  <div className="col-4 px-3">
                    <hr style={{ 'color': '#0A3DC2', 'height': '2px', 'opacity': '1' }} />
                  </div>
                  <div className="col-4 px-3">
                    <hr style={{ 'color': '#0A3DC2', 'height': '2px', 'opacity': '1' }} />
                  </div>
                </div>
                <div className="row">
                  <h5 className="col-4 text-center font-weight-bold">
                    Envie sua peça online
                  </h5>
                  <h5 className="col-4 text-center font-weight-bold">
                    Nós fabricamos sua peça imediatamente
                  </h5>
                  <h5 className="col-4 text-center font-weight-bold">
                    Suas peças são enviadas diretamente para você
                  </h5>
                </div>
                <div className="row">
                  <div className="col-4 text-center">
                    <span style={{ 'color': '#898989' }}>
                      Envie sei arquivo CAD para obter uma cotação online e um feedback automatico
                    </span>
                  </div>
                  <div className="col-4 text-center">
                    <span style={{ 'color': '#898989' }}>
                      O melhor fabricante em nossa rede de qualidade irá começar a produção imediatamente
                    </span>
                  </div>
                  <div className="col-4 text-center">
                    <span style={{ 'color': '#898989' }}>
                      Nós lidamos com toda a logística e comunicação, garantindo que suas peças cheguem até você em tempo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{height:50}}></div>
        <Parallax bgImage={bg1} strength={600}>
          <div style={{ 'height': '300px' }} />
        </Parallax>
        <div style={{height:50}}></div>
        <div className="row justify-content-center my-5" style={{padding:'0 10%', 'backgroundColor': '#EEF2FC' }}>
          <div className="col-0 col-xl-6">
          </div>
          <div className="col-10 col-lg-6 mt-5">
            <h4 className="font-weight-bold text-center" style={{ 'font-weight': '600' }}>
              Cote em segundos, peças em poucos dias
            </h4>
          </div>
          <div className="col-10">
            <div className="row justify-content-center my-4">
              <div className="col-12 col-lg-7 text-center mb-3" style={{ 'backgroundColor': '#D9D9D9', 'height': '270px' }}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <i class="fa-solid fa-play fa-2xl "></i>
              </div>
              <div className="col-12 col-lg-5 ps-5">
                <div className="row-12">
                  <div className="col col-lg-12 my-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                    <hr className="m-0" style={{ 'color': '#0A3DC2', 'height': '4px', 'opacity': '1' }} />
                    <div className="py-2 px-4">
                      <h5>
                        Cotação online
                      </h5>
                      <span style={{ 'color': '#57585A' }}>
                        Envie seu arquivo CAD para uma cotação online (STL, OBJ)
                      </span>
                    </div>
                  </div>
                  <div className="col col-lg-12 my-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                    <hr className="m-0" style={{ 'color': '#0A3DC2', 'height': '4px', 'opacity': '1' }} />
                    <div className="py-2 px-4">
                      <h5>
                        Atualização de preço em tempo real
                      </h5>
                      <span style={{ 'color': '#57585A' }}>
                        Acompanhe a atualização de preços em tempo real conforme altera materiais
                      </span>
                    </div>
                  </div>
                  <div className="col col-lg-12 my-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                    <hr className="m-0" style={{ 'color': '#0A3DC2', 'height': '4px', 'opacity': '1' }} />
                    <div className="py-2 px-4">
                      <h5>
                        Feedback DFM automático
                      </h5>
                      <span style={{ 'color': '#57585A' }}>
                        Receba feedback DFM automaticamente
                      </span>
                    </div>
                  </div>
                  <div className="col col-lg-12 my-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                    <hr className="m-0" style={{ 'color': '#0A3DC2', 'height': '4px', 'opacity': '1' }} />
                    <div className="py-2 px-4">
                      <h5>
                        Gerenciamento de pedidos
                      </h5>
                      <span style={{ 'color': '#57585A' }}>
                        Acompanhe a atualização de preços em tempo real conforme altera materiais
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 my-5 px-5 py-3 d-none d-lg-block" style={{ 'backgroundColor': '#CDD5EF' }}>
                <div className="container">
                  <h4 className="m-5 my-3" style={{ 'fontWeight': '600' }}>
                    Faça seu pedido no Cloud Cube
                  </h4>
                  <div className="row justify-content-center my-5">
                    <img src={groupFluxoImg} width="100%"></img>
                  </div>
                  <div className="row justify-content-center my-5 d-none" style={{ 'position': 'relative' }}>
                    <hr className="my-5" style={{ 'color': '#0A3DC2', 'height': '4px', 'opacity': '.5', 'zIndex': '1', 'position': 'absolute', 'width': '90%', 'top': '-30px' }} />
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={pranchetaIcon} className="w-100" />
                      </div>
                      <span>
                        Cotação on-line
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={subirArquivoIcon} className="w-100" />
                      </div>
                      <span>
                        Carregar arquivo
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={revisaoIcon} className="w-100" />
                      </div>
                      <span>
                        Revisão do pedido
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={carteiraIcon} className="w-100" />
                      </div>
                      <span>
                        Pagamento
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={fabricacaoIcon} className="w-100" />
                      </div>
                      <span>
                        Acompanhamento de fabricação em tempo real
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '50px' }}>
                        <img src={fastTruckIcon} className="w-100" />
                      </div>
                      <span>
                        Entrega
                      </span>
                    </div>
                    <div className="col text-center" style={{ 'zIndex': '2' }}>
                      <div className="m-auto mb-2" style={{ 'backgroundColor': '', 'width': '40px' }}>
                        <img src={boxIcon} className="w-100" />
                      </div>
                      <span>
                        Confirmação de recebimento
                      </span>
                    </div>
                  </div>
                </div>
              </div>
                <div style={{height:50}}></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center my-5">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-12 my-5">
                <h1 className="text-center" style={{ 'fontWeight': '600' }}>
                  Qualidade garantida em todo o processo
                </h1>
              </div >
              <div className="col-12 col-lg-3 m-4 my-5 text-center">
                <h3 className="mb-4" style={{ 'fontWeight': '600' }}>
                  Garantia de qualidade
                </h3>
                <span className="px-5 px-lg-0" style={{ 'color': '#57585A' }}>
                  Nós temos processos de gestão da qualidade para garantir que todas as peças sigam o mesmo padrão de qualidade.
                </span>
              </div>
              <div className="col-12 col-lg-3 m-4 my-5 text-center">
                <h3 className="mb-4" style={{ 'fontWeight': '600' }}>
                  Proteção de IP
                </h3>
                <span className="px-5 px-lg-0" style={{ 'color': '#57585A' }}>
                  Todos os arquivos enviados são confidenciais, temos o mesmo cuidado com a proteção da sua propriedade intelectual que temos com a nossa.
                </span>
              </div>
              <div className="col-12 col-lg-3 m-4 my-5 text-center">
                <h3 className="mb-4" style={{ 'fontWeight': '600' }}>
                  Proteção do comprador
                </h3>
                <span className="px-5 px-lg-0" style={{ 'color': '#57585A' }}>
                  Nós reembolsaremos se a qualidade das peças estiver fora do padrão ou se a peça estiver com com defeitos.
                </span>
              </div>
              <div className="col-12 col-lg-3 m-4 my-5 text-center">
                <h3 className="mb-4" style={{ 'fontWeight': '600' }}>
                  Acabamento e pós-processamento
                </h3>
                <span className="px-5 px-lg-0" style={{ 'color': '#57585A' }}>
                  Possuímos rigorosos requisitos técnicos para acabamento e pós-processamento.
                </span>
              </div>
              <div className="col-12 col-lg-3 m-4 my-5 text-center">
                <h3 className="mb-4" style={{ 'fontWeight': '600' }}>
                  Pagamento seguro
                </h3>
                <span className="px-5 px-lg-0" style={{ 'color': '#57585A' }}>
                  Pague com métodos de pagamento que você já conhece e confia.
                </span>
              </div>
            </div>
          </div>
        </div>

        <Parallax bgImage={bg2} strength={600}>
          <div style={{ 'height': '300px' }} />
        </Parallax>

        <div className="row justify-content-center my-5">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-10 text-center">
                <h1 style={{ 'fontWeight': '600' }}>
                  Galeria de peças fabricadas através de Cloud Cube
                </h1>
                <span style={{ 'color': '#57585A' }}>
                  Fabricamos protótipos rápidos e pedidos de produção de baixo volume para clientes em vários setores
                </span>
              </div>
              <Carousel className="mt-5" showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} selectedItem={asd}>
                <div className="row justify-content-center">
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca01} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Eixo tubular
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca02} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Eixo tubular
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca03} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Eixo tubular
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca04} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Eixo tubular
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca05} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Eixo tubular
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca06} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Suporte para eixo
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca07} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Suporte para eixo
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca08} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Troféu
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca09} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Troféu
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca10} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Troféu
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca11} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Roda para plataforma robótica
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca12} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Roda para plataforma robótica
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca13} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Roda para plataforma robótica
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca14} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Roda para plataforma robótica
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca15} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Adaptador de CLP Siemens para trilho DIN
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 col-lg-2 m-0 m-lg-2">
                    <img src={imgPeca16} />
                    <div className="p-2" style={{ 'backgroundColor': '#D1D5DE' }}>
                      <h5>
                        Adaptador de CLP Siemens para trilho DIN
                      </h5>
                    </div>
                  </div>
                </div>
              </Carousel>
              <div className="row justify-content-center mt-3">
                <button className="col-auto" style={{ 'border': 'none', 'color': '#D1D5DE', 'borderStartStartRadius': '4px', 'borderEndStartRadius': '4px' }} onClick={() => {
                  if (asd > 0) {
                    setAsd(prevState => {
                      return prevState - 1
                    })
                  }
                }}><i class="fa-solid fa-arrow-left fa-xl m-3" style={{ 'color': 'black' }} /></button>
                <button className="col-auto" style={{ 'border': 'none', 'color': '#D1D5DE', 'borderEndEndRadius': '4px', 'borderStartEndRadius': '4px' }} onClick={() => {
                  if (asd < 3) {
                    setAsd(prevState => {
                      return prevState + 1
                    })
                  }
                }}><i class="fa-solid fa-arrow-right fa-xl m-3" style={{ 'color': 'black' }} /></button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center my-5">
          <div className="col-10 mb-5 pb-5">
            <div className="row justify-content-center">
              <div className="col-12 text-center my-5 pt-5 pb-3">
                <h1>
                  Por quê escolher Cloud Cube
                </h1>
              </div>
              <div className="col-12 col-lg-6 justify-content-center text-center text-md-end pe-5" >
                <img src={logoMD} style={{height:'100%'}} />
              </div>
              <div className="col-12 col-lg-6 ps-5">
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Cotação online
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Feedback DFM automático
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Capacidade de produção sempre disponível
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Fácil gerenciamento de pedidos
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Time dedicado de especialistas
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Preços mais competitivos do mercado
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-check mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Capacidades abrangentes
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5 p-5" style={{ 'backgroundColor': '#010C28' }}>
          <div className="col-10">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h1 style={{ 'fontWeight': '600', 'color': '#FFFFFF' }}>
                  Coloque suas peças em produção agora mesmo
                </h1>
              </div>
              <div className="col-12 text-center my-5">
                <Link className="btn btn-primary" to='/calculate'>
                  Obter cotação agora
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
}

export default Landing;