import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer"
import logoMD from "../../assets/logoMD.svg";
import { Link } from "react-router-dom";

function Contact() {
  const [asd, setAsd] = useState(0)

  return (
    <main className="container-fluid px-0">
      <NavBar />
      <div className="col">
        <div className="row justify-content-center my-3">
          <div className="col-10 mb-5 pb-5">
            <div className="row justify-content-center">
              <div className="col-12 text-center my-5 pt-5 pb-3">
                <h1>
                  Fale conosco
                </h1>
              </div>
              <div className="col-12 col-lg-6 justify-content-center text-center text-md-end pe-5" >
                <img src={logoMD} style={{height:'100%'}} />
              </div>
              <div className="col-12 col-lg-6 ps-5">
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-envelope mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    contatoblitzar@gmail.com
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-brands fa-whatsapp mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    +55 (22) 99954-3445
                  </h5>
                </div>
                <div className="col-12 my-4">
                  <h5>
                    <i class="fa-solid fa-location-dot mx-3 fa-2xl" style={{ 'color': '#3162E8' }} />
                    Rua Múcio da Paixão, nº 282, Campos dos Goytacazes, RJ
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5 p-5" style={{ 'backgroundColor': '#010C28' }}>
          <div className="col-10">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h1 style={{ 'fontWeight': '600', 'color': '#FFFFFF' }}>
                  Coloque suas peças em produção agora mesmo
                </h1>
              </div>
              <div className="col-12 text-center my-5">
                <Link className="btn btn-primary" to='/calculate'>
                  Obter cotação agora
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
}

export default Contact;