import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
    const [config, setConfig] = useState()
    const [showTerms, setShowTerms] = useState(true)

    useEffect(() => {
        let cfg = JSON.parse(localStorage.getItem('@cloudcube:config') || '{}')
        console.log(cfg.showTerms)
        if (cfg && cfg.acceptedTerms) {
            setConfig(cfg)
            setShowTerms(false)
        }
    }, [])

    return (
        <React.Fragment>
            {showTerms &&
                <div className="card p-5" style={{ "position": "fixed", "left": "20px", "bottom": "20px", "zIndex": "100" }}>
                    <div className="mb-5">
                        <h4>Alerta de cookies, termos de uso e privacidade</h4>
                    </div>
                    <button className="btn btn-success w-25" style={{ "left": "20px" }}
                        onClick={() => {
                            setShowTerms(false)
                            localStorage.setItem('@cloudcube:config', JSON.stringify({ ...config, "acceptedTerms": true }));
                        }}>
                        ACEITAR
                    </button>
                </div>}
            <footer className="row justify-content-center pt-5" style={{ 'backgroundColor': '#000000', 'fontSize':'110%' }}>
                <div className="col-10">
                    <div className="row justify-content-center">
                        <div className="row row-cols-lg-6 p-5">
                            <div className="col-12 col-lg-auto">
                                <Link to="/landing" className="d-flex align-items-center mb-3 text-decoration-none text-light">
                                    <h4>
                                        Cloud Cube
                                    </h4>
                                </Link>
                            </div>

                            <div className="col d-none d-lg-block">

                            </div>

                            <div className="col-6 col-lg-auto">
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><strong><a href="#" className="nav-link p-0 text-light">Sobre nós</a></strong></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Quem somos</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Nossa plataforma</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Como trabalhamos</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Garantia de qualidade</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Proteção de IP</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Trabalhe conosco</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Seja um parceiro de fabricação</a></li>
                                </ul>
                            </div>

                            <div className="col d-none d-lg-block">
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><strong><a href="#" className="nav-link p-0 text-light">Nossas máquinas</a></strong></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Serviço de usinagem CNC</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Fresagem CNC</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Serviço de impressão 3D</a></li>
                                    {/* <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Serviço de fabricação de chapas metálicas</a></li> */}
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Serviço de prototipagem rápida</a></li>
                                </ul>
                            </div>

                            <div className="col d-none d-lg-block">
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><strong><a href="#" className="nav-link p-0 text-light">Suporte</a></strong></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Informações de envio</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Como pedir</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Como rastrear</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Entre em contato</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Serviço de prototipagem rápida</a></li>
                                </ul>
                            </div>

                            <div className="col-6 col-lg-auto">
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><strong><a href="#" className="nav-link p-0 text-light">Contato</a></strong></li>
                                    <li className="nav-item mb-2"><a href="mailto:contato.blitzar@gmail.com?Subject=Contato%20CloudCube" className="nav-link p-0 text-light">Mande um e-mail</a></li>
                                    <li className="nav-item mb-2"><a href="tel:5522999543445" className="nav-link p-0 text-light">Ligue: (22) 99954-3445</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Obter cotação</a></li>
                                    <li className="nav-item mb-2"></li>
                                    <li className="nav-item mb-2">
                                        <a target='_blank' href="https://www.instagram.com/cloudcube_/" className="nav-link font-weight-bold text-light mx-2" style={{ 'display': 'inline-block',  backgroundColor:'#0A3DC2', padding:'3px 6px', borderRadius:'100%', width:30, height:30 }}><i className="fa-brands fa-instagram fa-lg" style={{ 'color': 'black', 'fontSize':19,fontWeight:'bold', }} /></a>
                                        <a href="mailto:contatoblitzar@gmail.com" className="nav-link text-light mx-2" style={{ 'display': 'inline-block',  backgroundColor:'#0A3DC2', padding:'3px 6px', borderRadius:'100%', width:30, height:30   }}><i className="fa-solid fa-envelope fa-xl" style={{ 'color': 'black', 'fontSize':19,fontWeight:'bold', }} /></a>
                                        <a target='_blank' href="https://api.whatsapp.com/send?phone=5522999543445&text=&source=&data=#" className="nav-link text-light mx-2" style={{ 'display': 'inline-block',  backgroundColor:'#0A3DC2', padding:'3px 6px', borderRadius:'100%', width:30, height:30   }}><i className="fa-brands fa-whatsapp fa-xl" style={{ 'color': 'black', 'fontSize':19,fontWeight:'bold', }} /></a>
                                        {/* <a href="#" className="nav-link p-0 text-light mx-1" style={{ 'display': 'inline-block' }}><i className="fa-brands fa-square-youtube fa-xl" style={{ 'color': 'blue' }} /></a> */}
                                    </li>
                                </ul>
                            </div>

                            <hr className="w-100 mt-5" style={{ 'height': '3px' }} />
                            <span className="w-100" style={{ 'color': '#4A4D57' }}>Copyright © 2021 Cloud Cube</span>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer

