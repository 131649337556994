import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

function SideBar() {
  const { token, user } = useAuth();
  return (
    <div className="h-100" style={{ backgroundColor: 'rgb(33, 33, 33)' }}>
      <div className="text-center row">
        <div className="user col-12 mb-3">
          <div className="photo my-2 text-white text-center">
            <img src={"https://www.promoview.com.br/uploads/images/unnamed%2819%29.png" /*Config.URL_IMAGES + "clinicas/" + user?.foto*/}
              className="my-3"
              style={{
                "objectFit": "cover",
                "borderRadius": "50%",
                "height": "70px",
                "width": "70px"
              }} />
            <h5>{user?.email}</h5>
          </div>
        </div>
        <ul className="col" style={{ "listStyleType": "none" }}>
          <li className="w-100">
            <Link className={"btn btn btn-outline-secondary w-100 active"}
              style={{
                'border': 'none',
                'borderRadius': '0px',
              }}
              to={'/user/edit'}><h5 className={"text-dark"}>Editar perfil</h5></Link>
          </li>
          <li className="w-100">
            <Link className={"btn btn btn-outline-secondary w-100 active"}
              style={{
                'border': 'none',
                'borderRadius': '0px',
              }}
              to='/orders'><h5 className={"text-dark"}>Pedidos</h5></Link>
          </li>
          {user?.role == 'franqueado'
            ? <li className="w-100">
              <Link className={"btn btn btn-outline-secondary w-100 active"}
                style={{
                  'border': 'none',
                  'borderRadius': '0px',
                }}
                to='/franqueado'><h5 className={"text-dark"}>Franqueado</h5></Link>
            </li>
            : null}
          <li className="w-100">
            <a className={"btn btn btn-outline-secondary w-100 active"}
              style={{
                'border': 'none',
                'borderRadius': '0px',
              }}
              href='/'
              onClick={() => {
                localStorage.removeItem('@cloudcube:token');
                localStorage.removeItem('@cloudcube:user');
              }}><h5 className={"text-dark"}>Sair</h5></a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideBar;