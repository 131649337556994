import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RenderModel from "../../components/RenderModel";
import SideBar from "../../components/SideBar";
import api from "../../services/api";
import {formatCentsToReais} from "../../utils/currencyUtils"
import { Container, H1, Line, NavItem, ReturnButton, TopNav } from "./styles";
import PedidoFranqueado from "../../components/PedidoFranqueado";

export default function Franqueado() {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('@cloudcube:user')));
  const [inventory, setInventory] = useState({
    franquium_name: '',
    orders: {
      production: [],
      dispatch: [],
      maintenance: []
    }
  });

  const [details, setDetails] = useState({});

  function getFranqueado() {
    api.get('/franqueado/' + currentUser.id).then(response => {
      setInventory(response.data);
    })
  }

  function changeOrderStatus(id, status, numeroNF) {
    numeroNF = numeroNF || ""

    api.put('/franqueado/changeOrderStatus/' + id + '/' + status, {"numeroNF": numeroNF}).then(response => {
      getFranqueado();
    })
  }

  useEffect(() => {
    getFranqueado();
  }, [])

  return (
    <Container>
      <TopNav>
        <ReturnButton onClick={() => {
          window.location.href = '/';
        }} />
        <NavItem>
          Perfil Franqueado
        </NavItem>
      </TopNav>
      <container className="row justify-content-start" style={{ minHeight: '500px' }}>
        <div className="col-12 col-xl-3">
          <SideBar user={currentUser} />
        </div>
        <div className="col-10 col-xl-8">
          <h1>{inventory.franquium_name}</h1>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <div className="card-body">
                  <h2>Produção</h2>
                  {!(inventory.orders.production.length > 0)
                    ? <p>Fila de produção vazia</p>
                    : inventory.orders.production.map((order, index) => {
                      return (
                        <PedidoFranqueado key={index} order={order} statusChangeCallback={changeOrderStatus}/>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <div className="card-body">
                  <h2>Expedição</h2>
                  {!(inventory.orders.dispatch.length > 0)
                    ? <p>Fila de expedição vazia</p>
                    : inventory.orders.dispatch.map((order, index) => {
                      return (
                        <PedidoFranqueado key={index} order={order} statusChangeCallback={changeOrderStatus}/>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card" style={{"opacity": ".6"}}>
                <div className="card-body">
                  <h2>Manutenção</h2>
                  {!(inventory.orders.maintenance.length > 0)
                    ? <p>Sem manutenções</p>
                    : inventory.orders.maintenance.map((order, index) => {
                      return (
                        <div>Implementar aqui</div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </container>
    </Container>
  );
};