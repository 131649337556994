
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import api from "../../services/api";
import Footer from "../../components/Footer"
import { Container, H1, Line, NavItem, ReturnButton, TopNav } from "./styles";
import SideBar from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";

function Edit() {
    const { token, user } = useAuth();
    const [input, setInput] = useState({})
    const [responseStatus, setResponseStatus] = useState(null)

    useEffect(() => {
        api.get('/user/me/' + user.id).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    function handleChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInput(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    function send() {
        api.post('/user/edit/' + user.id, {
            'user': input
        }).then(response => {
            if (!response.data.message) {
                console.log(response.data)
                localStorage.setItem('@cloudcube:user', JSON.stringify({ ...user, ...response.data.current_user }));
                setResponseStatus(<div className="col-12 p-3 m-0 bg-success"><span>Concluido</span></div>)
            } else {
                setResponseStatus(<div className="col-12 p-3 m-0 bg-danger"><span>{response.data.message}</span></div>)
            }
        }).catch(error => {
            console.log(error)
            setResponseStatus(<div className="col-12 p-3 m-0 bg-danger"><span>Alguma coisa deu errado, tente novamente mais tarde.</span></div>)
        })
    }

    if (!user?.id) {
        return <Navigate to={'/signin'} />
    }

    return (
        <Container>
            <TopNav>
                <ReturnButton onClick={() => {
                    window.location.href = '/';
                }} />
                <NavItem>
                    Perfil
                </NavItem>
            </TopNav>
            <container className="row justify-content-start" style={{ minHeight: '500px' }}>
                <div className="col-12 col-xl-3">
                    <SideBar />
                </div>
                <div className="col-12 col-xl-9 p-0">
                    <div className="row">
                        {responseStatus}
                        <div className="col-sm-10 col-md-8 mx-auto mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <h2>Conta</h2>

                                    <div className="field">
                                        Email<br />
                                        <input type="text" name="email" className='form-control'
                                            value={input?.email}
                                            onChange={handleChange} />
                                    </div>

                                    <div className="field">
                                        Senha <i>(deixe em branco se não quiser mudar)</i><br />
                                        <input type="password" name="password" className="form-control"
                                            value={input?.password}
                                            onChange={handleChange} />
                                    </div>

                                    <div className="field">
                                        Confirmação de senha<br />
                                        <input type="password" name="confirmPassword" className="form-control"
                                            value={input?.confirmPassword}
                                            onChange={handleChange} />
                                    </div>

                                    {/* <div className="field">
                                        Senha atual<i>(precisamos da senha atual para confirmar as mudanças)</i><br />
                                        <input name="originalPassword" className="form-control"
                                            value={input?.originalPassword}
                                            onChange={handleChange} />
                                    </div> */}

                                    <div className="actions">
                                        <button className="btn btn-primary my-3" onClick={() => send()}>Atualizar</button>
                                    </div>

                                    <Link className="btn btn-secondary btn-sm">Voltar</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </container>
            <Footer />
        </Container>
    )
}

export default Edit