import Axios from "axios";
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

import AddressForm from "../../components/addressForm";
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"
import RenderModel from "../../components/RenderModel";
import BudgetForm from "../../components/BudgetForm";

export default function ConfirmationStep() {
    const navigate = useNavigate();
    const location = useLocation();
    const { token, user } = useAuth();
    const [firstRender, setFirstRender] = useState(true);
    const [materials, setMaterials] = useState()
    const [selectedMaterial, setSelectedMaterial] = useState(location.state.material);
    const [selectedQuantity, setSelectedQuantity] = useState(location.state.quantity);
    const [selectedCategory, setSelectedCategory] = useState(location.state.category);
    const [selectedSheetDimensions, setSelectedSheetDimensions] = useState(location.state.sheetDimensions);
    const [selectedEspessura, setSelectedEspessura] = useState(location.state.espessura || null);
    const [selectedFile, setSelectedFile] = useState(location.state.file);
    const [format, setFormat] = useState(location.state.format);
    const [renderGCode, setRenderGCode] = useState(false);
    const [GCodeFile, setGCodeFile] = useState();
    const [size, setSize] = useState(location.state.size);
    const [orcamento, setOrcamento] = useState(location.state.orcamento);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const [address, setAddress] = useState(location.state.address);
    const [renderAddressForm, setRenderAddressForm] = useState(false);

    const endpointMap = {
        'Impressão 3D': 'orcamento',
        'Usinagem CNC': 'orcamento2d',
        'Corte a laser': 'orcamento2d',
    }

    function getMaterial() {
        api.get('material').then(response => {
            setMaterials({
                'Impressão 3D': response.data.materials['impressora_3d'],
                'Usinagem CNC': response.data.materials['usinagem_cnc'],
                'Corte a laser': response.data.materials['laser'],
            })
        })
    }

    function updateOrc() {
        setLoading(true);

        let formData = new FormData();
        formData.append('fileName', selectedFile.name);
        formData.append('quantidade', selectedQuantity);
        formData.append('material', selectedMaterial);
        formData.append('sheetDimension', selectedSheetDimensions);
        if (size){
            formData.append('width', size.width);
            formData.append('height', size.height);
            formData.append('length', size.length);
        }
        formData.append('tipo', selectedCategory);
        formData.append('orcamento_id', orcamento.orcamento_id);
        formData.append('espessura', selectedEspessura)

        api.post(endpointMap[selectedCategory], formData).then(e => {
            setLoading(false);
            setOrcamento(e.data)
        }).catch((e) => {
            setLoading(false);
            setError(e);
            console.error(e);
        });
    }

    function handleSaveToCart() {
        api.post(`/product/${orcamento.orcamento_id}/add_to_cart`, {}).then(e => {
            navigate('/carrinho',{
                state: {
                    address: address,
                }
            })
        });
    }

    function getMaterialNameById(materialId){
        var foundMaterial = materials[selectedCategory].find(
            material => material.id == materialId
        );

        if (foundMaterial){
            return foundMaterial.name;
        }
    }

    function onBudgetFormChange(values){
        setSelectedMaterial(values.selectedMaterial);
        setSelectedSheetDimensions(values.selectedSheetDimensions);
        setSelectedQuantity(values.selectedQuantity);
    }

    useEffect(() => {
        getMaterial()
        setFirstRender(false)
    }, [])

    useEffect(() => {
        // Ignorar a chamada se o material ou a quantidade não estiverem definidos
        if (!(selectedMaterial && selectedQuantity)){
            return;
        }

        // Ignorar a chamada se sheetDimensions não estiver definido e for uma impressão 2D
        if (endpointMap[selectedCategory] == 'orcamento2d' && !selectedSheetDimensions){
            return;
        }

        // Ignorar a chamada se for a primeira renderização
        if (firstRender) {
            return;
        }
        
        updateOrc();
    }, [selectedMaterial, selectedQuantity, selectedSheetDimensions])

    useEffect(() => {
        console.log('location', location.state)
        if (renderGCode && !GCodeFile) {
            let path = (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/') + orcamento.gcode
            Axios.get(path).then(e => {
                setGCodeFile(e.data)
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [renderGCode])

    if (!materials) {
        return <div />
    }

    return (
        <React.Fragment>
            {renderAddressForm && <AddressForm address={address} setAddress={setAddress} setRenderAddressForm={setRenderAddressForm} />}
            <main className="container-fluid px-0">
                <NavBar />
                <div className="col mx-auto my-5" style={{ 'maxWidth': '1230px' }}>
                    <div className="row">
                        <div className="col col-8">
                            <div className="row mb-4">
                                <div className="col-auto">
                                    <h1>Especificações da peça</h1>
                                </div>
                                <div className="col-auto ms-auto me-3">
                                    <div className="row mx-auto">Visualizar</div>
                                    <div className="btn-group">
                                        <button style={{ 'width': '60px' }}
                                            className={renderGCode
                                                ? "btn btn-primary"
                                                : "btn btn-outline-primary"}
                                            onClick={() => {
                                                console.log('gcode')
                                                setRenderGCode(true)
                                            }}>
                                            <span style={{ 'fontSize': '10px' }}>GCODE</span>
                                        </button>
                                        <button style={{ 'width': '60px' }}
                                            className={!renderGCode
                                                ? "btn btn-primary"
                                                : "btn btn-outline-primary"}
                                            onClick={() => {
                                                console.log(format)
                                                setRenderGCode(false)
                                            }}>
                                            <span style={{ 'fontSize': '10px' }}>{format.toUpperCase()}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="px-5 mb-4">
                                <tr>
                                    <th style={{ 'width': '350px' }}>Peça</th>
                                    <th style={{ 'width': '210px' }}>Especificações</th>
                                    <th style={{ 'width': '140px' }}>Quantidade</th>
                                    <th style={{ 'width': 'auto' }}>Preço</th>
                                </tr>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="p-5 pb-4" style={{ 'minHeight': '300px', 'borderWidth': '1px', 'borderStyle': 'solid', 'borderColor': '#3162E8', 'borderRadius': '5px' }}>
                                        <tr>
                                            <th style={{}}>
                                                <div style={{ 'width': '350px', 'textOverflow': 'ellipsis', 'overflow': 'hidden' }}>
                                                    <div className="pe-2 pb-2" style={{}}>
                                                        {renderGCode && GCodeFile
                                                            ? <RenderModel model={selectedFile} GCode={GCodeFile} height={200} />
                                                            : <RenderModel model={selectedFile} height={200} />}

                                                    </div>
                                                    <h5 className="fw-bold">{selectedFile.name}</h5>
                                                </div>


                                            </th>
                                            <th style={{ 'width': '200px', 'verticalAlign': 'top' }}>
                                                <tr>
                                                    <td>
                                                        <p style={{ 'fontSize': '15px', 'color': '#808080' }}>{selectedCategory}</p>
                                                    </td>
                                                </tr>
                                                {materials && selectedCategory &&
                                                <>
                                                    <tr>
                                                        <td>
                                                            <p style={{ 'fontSize': '15px', 'color': '#808080' }}>
                                                                {getMaterialNameById(selectedMaterial)}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </>
                                                }
                                                
                                                { size &&
                                                <>
                                                    <tr>
                                                        <td>
                                                            <p style={{ 'fontSize': '15px', 'color': '#808080' }}>Dimensões:</p>
                                                        </td>
                                                    </tr>
                                                    {size.width && size.height && size.length &&
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <p style={{ 'fontSize': '15px', 'color': '#808080' }}>
                                                                        {size.width.toFixed(2)} x {size.height.toFixed(2)} x {size.length.toFixed(2)} mm
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p style={{ 'fontSize': '15px', 'color': '#808080' }}>
                                                                        {(size.width * size.height * size.length).toLocaleString('pt-BR', { maximumFractionDigits: 2 })} mm3
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    }
                                                    
                                                </>}

                                            </th>
                                            <th align="center" style={{ 'width': '100px', 'verticalAlign': 'top' }}>
                                                <p style={{ 'textAlign': 'center' }}>
                                                    {selectedQuantity}
                                                </p>
                                            </th>
                                            <th style={{ 'width': 'auto', 'verticalAlign': 'top' }}>
                                                <h5 style={{ 'textAlign': 'right' }}>{(orcamento.sub_price.cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h5>
                                                <p style={{ 'textAlign': 'right', 'fontSize': '10px', 'color': '#808080' }}>Valor p/unidade</p>
                                            </th>
                                        </tr>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-4" style={{ 'borderLeft': '1px solid #A3A3A3' }}>
                            <div className="mb-4">
                                <h5>Máquina</h5>
                                <select className="form-control form-control-lg form-select border border-primary"
                                    onChange={e => setSelectedCategory(e.target.value)}>
                                    { /* Não pode mudar a máquina de um pedido com arquivo 3D */ }
                                    {selectedCategory == "Impressão 3D" &&
                                        <option value="Impressão 3D" selected>Impressão 3D</option>
                                    }
                                    { /* É permitido mudar entre as duas máquinas com pedidos 2D */ }
                                    {
                                        selectedCategory != "Impressão 3D" &&
                                        <>
                                        <option value="Usinagem CNC" selected>Usinagem CNC</option>
                                        <option value="Laser" disabled>Laser</option>
                                        </>
                                        
                                    }
                                </select>
                            </div>

                            <BudgetForm materialList={materials} selectedCategory={selectedCategory} preselectedMaterial={selectedMaterial} preselectedQuantity={selectedQuantity} onValueChange={onBudgetFormChange}/>

                            <div className="mb-4 mt-4">
                                <h5>Impressoras disponiveis</h5>
                                <input className="form-control" type="text" value={orcamento.available_printers} disabled m-2 />
                            </div>
                            <div className="mb-4 py-3" style={{ 'borderWidth': '1px', 'borderStyle': 'solid', 'borderColor': '#3162E8', 'borderRadius': '5px', 'backgroundColor': ' #d6eaf8 ' }}>
                                <p className="fw-bold" style={{ 'textAlign': 'center', 'color': '#83A1F1' }}>Tempo de impressão estimado</p>
                                <h5 className="fw-bold m-0" style={{ 'textAlign': 'center' }}>{orcamento.print_time}</h5>
                            </div>
                            <div className="mb-4">
                                <hr />
                                <button className="btn w-100 mb-4 py-3 px-4" style={{ 'borderWidth': '1px', 'borderStyle': 'solid', 'borderColor': '#3162E8', 'borderRadius': '5px' }}
                                    onClick={() => setRenderAddressForm(true)}>
                                    <p className="fw-bold m-0" style={{ 'color': '#A3A3A3', 'textAlign': 'left' }}>Endereço de entrega</p>
                                    <p className="fw-bold text-primary mt-1 fs-6 m-0" style={{ 'textAlign': 'left' }}>Digite o endereço de entrega</p>
                                </button>
                            </div>
                            <div className="mb-4">
                                <hr />
                                <div className="row" >
                                    <div className="col col-auto">
                                        Subtotal
                                    </div>
                                    <div className="col col-auto ms-auto">
                                        {(orcamento.sub_price.cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <hr />
                                <div className="row" >
                                    <div className="col col-auto">
                                        <h5 className="m-0 font-weight-bold">Total</h5>
                                    </div>
                                    <div className="col col-auto ms-auto">
                                        <h5 className="m-0 font-weight-bold">
                                            {(orcamento.price.cents / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                {user
                                    ? <button className={(loading && selectedQuantity > 0)
                                        ? "btn btn-outline-primary w-100 p-3 disabled"
                                        : "btn btn-outline-primary w-100 p-3"}
                                        onClick={() => handleSaveToCart()}>{loading ? 'Carregando...' : 'Salvar no carrinho'}</button>
                                    : <Link className={loading
                                        ? "btn btn-outline-primary w-100 p-3 disabled"
                                        : "btn btn-outline-primary w-100 p-3"}
                                        to="/signin?fromBudget=true"
                                        state={{
                                            address: address,
                                            material: selectedMaterial,
                                            quantity: selectedQuantity,
                                            category: selectedCategory,
                                            espessura: selectedEspessura,
                                            file: selectedFile,
                                            format: selectedFile.name.split('.').reverse()[0],
                                            size: {
                                                width: size.width,
                                                height: size.height,
                                                length: size.length,
                                            },
                                            orcamento: orcamento,
                                        }}>Faça login para enviar seu pedido</Link>}
                            </div>
                            <div className="mb-4">
                                <button className="btn btn-outline-primary w-100 p-2" disabled>Entre em contato com o suporte</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main >
        </React.Fragment>
    )
}