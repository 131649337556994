import React, { useEffect } from "react"

function Step3({ setLockProgress, setOrder, orderInformation, throwErrorMessage }) {

    function onchange2d(event) {
        setLockProgress(4)
        // document.getElementById('container').innerHTML = '<div id="model" style="width: 100%; height: 500px; margin:0 auto;"> </div>';
        var file = document.getElementById('load2dfile').files[0];
        var filename = file.name;
        var extension = filename.split('.').pop().toLowerCase();
        console.log(event.target.files[0])
        setOrder('file', {
            'pointer': 'svg',
            [event.target.name]: event.target.files[0],
            'name': event.target.files[0].name
        })
    }
    function onchangestl(event) {
        setLockProgress(4)
        // document.getElementById('container').innerHTML = '<div id="model" style="width: 100%; height: 500px; margin:0 auto;"> </div>';
        var file = document.getElementById('loadStl').files[0];
        var filename = file.name;
        var extension = filename.split('.').pop().toLowerCase();
        var reader = new FileReader();
        reader.addEventListener('load', function (event) {
            var contents = event.target.result;
            // var material = new THREE.MeshStandardMaterial();
            // var mesh = new THREE.Mesh( geometry, material );
            // mesh.name = filename;
            // editor.execute( new AddObjectCommand( editor, mesh ) );
        }, false);
        if (reader.readAsBinaryString !== undefined) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
        console.log(event.target.files[0])
        setOrder('file', {
            'pointer': 'stl',
            [event.target.name]: event.target.files[0],
        })
    };

    useEffect(() => {
        setLockProgress(3)
        throwErrorMessage(3)
    }, [])

    return (
        <React.Fragment>
            {orderInformation.setor == '2d'
                ? <div className="col">
                    <h4 className="my-3">
                        Carregue seu SVG e clique em próximo
                    </h4>
                    Carregue seu SVG:
                    <input type="file" name="svg" onChange={onchange2d} id="load2dfile" accept=".svg" />
                </div>
                : <div className="col">
                    <h4 className="my-3">
                        Carregue seu STL e clique em próximo
                    </h4>
                    Carregue seu STL:
                    {/* <input type="file" name="stl" onchange="onchangestl();" id="loadStl" accept=".stl" /> */}
                    <input type="file" name="stl" onChange={onchangestl} id="loadStl" accept=".stl" />
                </div>}
        </React.Fragment>
    )
}

export default Step3