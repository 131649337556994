import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Input from "../../components/Input";
import { initialValues } from "./formikProps";

import { useAuth } from "../../hooks/auth";
import { Container, AnimationContainer, Button } from "./styles";

import logoImg from "../../assets/logoMD.svg";
import googleIcon from "../../assets/google 1.png"

import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import Axios from "axios";

const SignIn = () => {
  const MySwal = withReactContent(Swal);
  const fromBudget = new URL(window.location.href).searchParams.get('fromBudget');
  const location = useLocation();
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const clientId = '733750034881-e9krm5kik1cqacrb1h7bk3llsvj8mmhf.apps.googleusercontent.com'

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const onSuccess = (res) => {
    console.log('success:', res);
    let path = (process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/');
    Axios.post(path + 'api/v1/googleAuth', {
      "user": {
        "email": res.profileObj.email,
      }
    }).then(response => {
      if (!response.data.success) {
        let randomPass = [1, 2, 3, 4, 5, 6].map(value => Math.floor(Math.random() * 10)).join('')
        Axios.post(path + 'users', {
          "user": {
            "email": res.profileObj.email,
            "password": randomPass,
            "password_confirmation": randomPass
          }
        }).then(response => {
        });
      }
    });
  };
  const onFailure = (err) => {
    console.log('failed:', err);
  };

  async function handleSubmit(values) {
    try {
      await signIn({
        cpf: values.cpf,
        password: values.password,
      });

      fromBudget
        ? navigate("/calculate/confirmation", {
          state: location.state
        })
        : navigate("/");
    } catch (error) {
      // console.log('Erro na Autenticação:', error.request, error.response)
      console.log('Erro na Autenticação:', error.request)

      if (error.request['status'] == 401){ // Unauthorized
        MySwal.fire({
          icon: "error",
          title: "Erro de Autenticação!",
          text: "Verifique se o e-mail e a senha estão corretos",
        });
      }
      else {
        MySwal.fire({
          icon: "error",
          title: "Erro de autenticação!",
          text: "Conexão com o site falhou! Tente novamente.",
        });
      }
      
    }
  }

  return (
    <Container>
      <AnimationContainer>
        <div className="p-5">
          <img src={logoImg} alt="Cloud Cube" style={{ width: "100%" }} className="mb-5" />
        </div>
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          validateOnMount
          initialValues={initialValues}
        >
          <Form style={{ "textAlign": 'left' }}>
            <h5 className="text-primary font-weight-bold mt-5">Endereço de e-mail</h5>
            <Field as={Input} name="cpf" placeholder="E-mail" style={{ 'marginTop': '-1.4rem' }} />
            <h5 className="text-primary font-weight-bold mt-3">Senha</h5>
            <Field
              as={Input}
              name="password"
              type="password"
              placeholder="Password"
              style={{ 'marginTop': '-1.4rem' }}
            />
            <Button type="submit">Entrar</Button>
          </Form>
        </Formik>

        <Link className="mt-2 text-primary" to="/signup" state={location.state}>Registre-se</Link>
        <Link className="mt-2 text-primary" to="/recoverPassword">Esqueci minha senha</Link>
        {/* <button className="btn btn-outline-secondary p-3 mt-4" style={{ 'width': '340px' }}>
          <h5 className="mb-0"><i class="fa-brands fa-google me-2 fa-xl" style={{ 'color': '#007bff' }} />Entrar com o Google</h5>
        </button> */}
        {/* <GoogleLogin
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        /> */}
        <Link className="mt-2 text-primary" to="/">Retornar à página inicial</Link>
      </AnimationContainer>
    </Container >
  );
};

export default SignIn;
