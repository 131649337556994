
import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { useDropzone } from 'react-dropzone'
import { Navigate, useNavigate } from "react-router-dom";
import { Collapse, Row, Col } from 'react-bootstrap';

import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import RenderModel, { GetModelDimensions } from "../../components/RenderModel";
import BudgetForm from "../../components/BudgetForm";

function Orcamento() {
    const [materials, setMaterials] = useState()
    const [showForm, setShowForm] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSheetDimensions, setSelectedSheetDimensions] = useState();
    const [selectedTipo, setSelectedTipo] = useState();
    const [selectedEspessura, setSelectedEspessura] = useState(1);
    const [selectedFile, setSelectedFile] = useState();
    const [protectInputFromMultclick, setProtectInputFromMultclick] = useState(false)
    const [dropdown, setDropdown] = useState();
    const [modelSize, setModelSize] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (file) => handleFile(file[0])
    });
    const inputFile = useRef(null);
    const navigate = useNavigate();

    const categories = [
        {
            'dropdown': false,
            'category': 'Impressão 3D',
            'types': [{
                'name': 'FDM',
                'description': 'Focused Deposition Modeling'
            }]
        },
        {
            'disabled': false,
            'dropdown': false,
            'category': 'Usinagem CNC',
            'types': [{
                'name': 'Fresagem (3 eixos)',
            }]
        },
        {
            'disabled': true,
            'dropdown': true,
            'category': 'Corte a laser',
            'types': [{
                'name': 'Madeira',
            }, {
                'name': 'Acrílico',
            }, {
                'name': 'MDF',
            }, {
                'name': 'Couro',
            }]
        }
    ];

    function getMaterial() {
        api.get('material').then(response => {
            setMaterials({
                'Impressão 3D': response.data.materials['impressora_3d'],
                'Usinagem CNC': response.data.materials['usinagem_cnc'],
                'Corte a laser': response.data.materials['laser'],
            })
        })
    }

    function handleFile(file) {
        setSelectedFile(file)
    }

    useEffect(() => {
        getMaterial()
    }, [])

    useEffect(() => {
        if (selectedFile && selectedCategory === "Impressão 3D") {
            GetModelDimensions(selectedFile, setModelSize)
        }
    }, [selectedFile])

    useEffect(() => {
        setShowForm(selectedCategory !== undefined);
        setSelectedFile(undefined);
    }, [selectedCategory])

    function renderCategories(categories) {
        return categories.map((value, index) => {
            return (
                <div className="col me-3"
                    key={index}
                    onMouseLeave={() => setDropdown()}>
                    <button
                        className={selectedCategory == value.category
                            ? "btn btn-outline-primary w-100 h-100 p-3 active"
                            : value.disabled
                                ? "btn btn-outline-primary w-100 h-100 p-3 disabled"
                                : "btn btn-outline-primary w-100 h-100 p-3"}
                        onMouseEnter={() => value.dropdown && setDropdown(value.category)}
                        onClick={() => {
                            setSelectedCategory(value.category)
                            setSelectedMaterial(materials[value.category][0].id)
                        }}>
                        <h5 className="m-3">{value.category}</h5>
                        {value.disabled
                            ? <p className="text-danger">NÃO DISPONÍVEL</p>
                            : value.types.map((type, index) => {
                                return <span key={index}>{index > 0 ? ', ' : ''}{type.name}</span>
                            })}
                    </button>
                    {dropdown == value.category
                        ? buildDropdown(value)
                        : null}
                </div>
            );
        });
    }

    function buildDropdown(value) {
        return <div className="list-group-item match border-secondary p-0"
            style={{ position: 'absolute', zIndex: '5', "minWidth": '316px' }}
            onMouseLeave={() => setDropdown()}>
            {value.types.map((type, index) => {
                return <React.Fragment>
                    <div className="p-4"
                        key={index}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'grey'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                        onClick={() => {
                            setSelectedCategory(value.category)
                            setSelectedTipo(type.name)
                            setSelectedMaterial(materials[value.category][0].id)
                            setDropdown();
                        }}>
                        <h5 className="text-dark">{type.name}</h5>
                        {type.description && <span>{type.description}</span>}
                    </div>
                </React.Fragment>
            })}
        </div>
    }

    function sendBudget(event) {
        setLoading(true);
        setError();
        console.log("Enviando arquivo: ", selectedFile)

        let formData = new FormData();
        formData.append('fileName', selectedFile.name);
        formData.append('quantidade', selectedQuantity);
        formData.append('material', selectedMaterial);
        formData.append('sheetDimension', selectedSheetDimensions);
        // formData.append('tipo', selectedTipo);
        if (modelSize){
            formData.append('width', modelSize.width);
            formData.append('height', modelSize.height);
            formData.append('length', modelSize.length);
        }
        formData.append('tipo', selectedCategory);
        formData.append('file', selectedFile);
        formData.append('espessura', selectedEspessura);

        const endpointMap = {
            'Impressão 3D': 'orcamento',
            'Usinagem CNC': 'orcamento2d',
            'Corte a laser': 'orcamento2d',
        }

        api.post(endpointMap[selectedCategory], formData).then(e => {
            setLoading(false);
            redirectToConfirmationPage(e.data);
        }).catch((e) => {
            setLoading(false);
            setError(e.response.data.error);
            console.error(e);
        });
    }

    function redirectToConfirmationPage(orcamento) {
        navigate("/calculate/confirmation", {
            state: {
                material: selectedMaterial,
                quantity: selectedQuantity,
                category: selectedCategory,
                // tipo: selectedTipo,
                espessura: selectedEspessura,
                file: selectedFile,
                format: selectedFile.name.split('.').reverse()[0],
                size: {
                    width: modelSize?.width,
                    height: modelSize?.height,
                    length: modelSize?.length,
                },
                orcamento: orcamento,
            }
        });
    }

    function onBudgetFormChange(values){
        setSelectedMaterial(values.selectedMaterial)
        setSelectedSheetDimensions(values.selectedSheetDimensions)
        setSelectedQuantity(values.selectedQuantity)
    }

    return (
        <main className="container-fluid px-0">
            <NavBar />
            {error
                && <div className="alert alert-danger" role="alert">
                    <div className="col col-10 mx-auto">
                        {/* <h5 className="m-0">Falha ao processar o arquivo<br/>Verifique se o arquivo tem formato STL e não excede 100MB.</h5> */}
                        <h5 className="m-0">{error}</h5>
                    </div>
                </div>}
            <div className="col mx-auto my-5" style={{ 'maxWidth': '1230px' }}>
                <div className="col col-10 mx-auto">
                    <div className="row mt-5">
                        <h4 className="mb-4">Escolha o tipo de máquina</h4>
                        {renderCategories(categories)}
                    </div>

                    <Collapse in={showForm}>
                        <div>
                            <div className="row mt-5">
                                <h4 className="mb-4">Envie seu arquivo</h4>
                                <div className="card card-body pt-5"
                                    style={{ 'borderStyle': 'dashed', 'borderWidth': '1px', 'borderColor': '#0d6efd' }}
                                    {...getRootProps()}>
                                    <input type="file"
                                        accept=".stl,.svg"
                                        ref={inputFile}
                                        style={{ 'display': 'none' }}
                                        onChange={(event) => {
                                            handleFile(event.target.files[0])
                                        }} />
                                    <div className="mx-auto my-5">
                                        <h5 className="text-secondary">Arraste e solte ou</h5>
                                        <button className="btn btn-primary"
                                            disabled={protectInputFromMultclick}
                                            onClick={() => {
                                                setProtectInputFromMultclick(true)
                                                setTimeout(() => {
                                                    setProtectInputFromMultclick(false)
                                                }, "1000")
                                                inputFile.current.click();
                                            }}>
                                            <i className="fa-solid fa-arrow-up-from-bracket me-2" />
                                            Escolher arquivo
                                        </button>
                                    </div>
                                    <hr className="mt-5" style={{ 'color': '#0d6efd', 'height': '2px' }} />
                                    {selectedFile
                                        ? <span className="mx-auto">{selectedFile.name}</span>
                                        : <span className="mx-auto">
                                            Tipos de arquivos suportados: <span className="text-secondary">
                                                {(() => {
                                                    switch (selectedCategory) {
                                                        default:
                                                        case 'Impressão 3D':
                                                            return 'STL'; // STL, OBJ
                                                        case 'Usinagem CNC':
                                                            return 'SVG'; // 'STEP, 3DM'
                                                        case 'Corte a laser':
                                                            return 'SVG'; // 'STEP, 3DM, SVG, DWF, DWG'
                                                    };
                                                })()}
                                            </span>
                                        </span>}
                                </div>
                                <div className="row mt-2">
                                    <small className="text-muted">Limite de 100MB. Caso o arquivo exceda o limite, entre em contato por e-mail.</small>
                                </div>
                            </div>

                            <BudgetForm materialList={materials} selectedCategory={selectedCategory} onValueChange={onBudgetFormChange}/>
                        </div>
                    </Collapse>
                    <div className="row mt-5">
                        <button to="/carrinho" style={{ 'width': '120px' }}
                            className={
                                (selectedMaterial && selectedQuantity && selectedQuantity > 0 && selectedFile && selectedCategory && !loading)
                                    ? "btn btn-primary text-white ms-auto"
                                    : "btn btn-primary text-white disabled ms-auto"
                            }
                            onClick={sendBudget}>
                            {loading
                                ? 'Carregando...'
                                : <>Próximo <i className="fa-solid fa-arrow-right ms-2" /></>}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default Orcamento